import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import HomeNavbar from '../layout/HomeNavBar';
import firebase from '../../firebase';
import { Consumer } from '../../context';
import $ from 'jquery';
//import 'jquery-ui';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class SignUpStep4 extends React.Component  {
    state = {
        loginEmail: '',
        loginPassword: '',
        email: '',
        password: '',
        isLoggedIn: false,
        userID: '',
        fullName: ''
    }
    
    componentWillMount() {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/script.js");

        this.setState({
            ...this.state,
            isLoggedIn: false,
            userID: '',
            fullName: ''
        });

        localStorage.setItem('robberbaron', JSON.stringify(this.state));
    }

    handleSubmit = (dispatch, e) => {
        e.preventDefault()

        console.log('Submit button clicked!');
        $('#signInButton').text('Please Wait');
        $('#signInButton').prop('disabled', true);

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .then((user) => {
                var user = firebase.auth().currentUser;
                console.log('Successfully logged in with user ID: ', user.uid); // Optional
                console.log('The data type is: ', typeof user.uid); // Optional

                /*localStorage.setItem('robberbaron', JSON.stringify({
                    ...this.state,
                    isLoggedIn: true,
                    userID: user.uid
                }));*/
                
                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data()
                        console.log('The full name is: ', fullName);
                        console.log('The userID is: ', userID);

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });

                        console.log("The state: ", this.state);                
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            }, function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log('Error: ' + error.code + ' ', errorMessage);

                if (errorCode === "auth/user-not-found") {
                    $('#loginFormMessage').text('The email you entered is wrong. Please try again.');
                } else if (errorCode === "auth/wrong-password") {
                    $('#loginFormMessage').text('The password is wrong. Please try again.');
                } else {
                    $('#loginFormMessage').text('There was an error logging. Please try again.');
                }

                $('#signInButton').text('Sign In');
                $('#signInButton').prop('disabled', false);
                
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            });

        /*firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
            .then(function(user) {
                var user = firebase.auth().currentUser;
                console.log(user.uid); // Optional
            }, function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
            });*/
    }

    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({
            [e.target.id]: e.target.value
        })

        $('#loginFormMessage').css('color', '#000000');
        $('#loginFormMessage').text('Sign in to your account to continue using RobberBaron');
    }

    handleClick = (dispatch, e) => {
        e.preventDefault()
        console.log('Facebook button clicked')

        if (e.target.id === 'facebookSignIn') { 
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user);

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data()
                        console.log('The full name is: ', fullName);
                        console.log('The userID is: ', userID);

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });

                        console.log("The state: ", this.state);                
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
        } else if (e.target.id === 'forgotPassword') {
            if (this.state.email === '') {
                $('#loginFormMessage').text('Please fill in your email.');
                    
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            } else {
                var auth = firebase.auth();
                var emailAddress = this.state.email

                auth.sendPasswordResetEmail(emailAddress).then(function() {
                    $('#loginFormMessage').text('Your password has been reset. Please check your email.');
                }).catch(function(error) {
                    $('#loginFormMessage').text('There was an error reseting your password. Please try again.');
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                });
            }
        } else {
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data()
                        console.log('The full name is: ', fullName);
                        console.log('The userID is: ', userID);

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });

                        console.log("The state: ", this.state);                
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
              }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
        }
    }

    render() {
        const { isLoggedIn } = this.state;

        if (isLoggedIn) {
            $(".float-close-pro").click();
            this.props.history.push('/dashboard');
        }

        return (
            <Consumer>
                {value => {
                    const { dispatch } = value;
        return (
            <div className="App">
                <HomeNavbar />
                <div id="content-pro">
			
            <div class="container">
                 <div class="centered-headings-pro pricing-plans-headings">
                     <h6>For one low monthly price</h6>
                     <h1>Instantly watch Premium Content From Your Favorite Creator</h1>
                 </div>
         </div>
         
         <div class="container">
             <ul id="registration-steps-pro">
                 <li>
                    <NavLink to="/signup-step1"> 
                         <div class="registration-step-circle-icon">01</div>
                         <div class="registration-step-number">Step 1</div>
                         <h5 class="registration-step-title">Choose your plan</h5>
                    </NavLink>
                 </li>
                 <li>
                    <NavLink to="/signup-step2"> 
                         <div class="registration-step-circle-icon">02</div>
                         <div class="registration-step-number">Step 2</div>
                         <h5 class="registration-step-title">Create your account</h5>
                    </NavLink>
                 </li>
                 <li>
                    <NavLink to="/signup-step3"> 
                         <div class="registration-step-circle-icon">03</div>
                         <div class="registration-step-number">Step 3</div>
                         <h5 class="registration-step-title">Billing information</h5>
                    </NavLink>
                 </li>
                 <li class="current-menu-item">
                    <NavLink to="/signup-step4"> 
                         <div class="registration-step-circle-icon">04</div>
                         <div class="registration-step-number">Step 4</div>
                         <h5 class="registration-step-title">Welcome to RobberBaron!</h5>
                    </NavLink>
                 </li>
             </ul>
             <div class="clearfix"></div>
         </div>
         
         
         <div id="pricing-plans-background-image">
             <div class="container">
                     <div class="registration-steps-page-container">
                         
                         
                         <div class="registration-step-final-padding">
                             <h2 class="registration-final-heading">Welcome <span>John Doe!</span> Just one more steps to compvare your account setup</h2>
                             
                             <div class="registration-invite-friends">
                                                                     
                                 <form class="registration-invite-friends-form">
                                     <div class="form-group">
                                         <label for="full-name" class="col-form-label">Invite your friends</label>
                                         <input type="text" class="form-control" id="full-name" placeholder="Enter email separated by commas"/>
                                     </div>
                                     
                                     {/*<div class="form-group-invite-friends">
                                         <ul class="registration-invite-friends-list">
                                             <li  class="active">
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-1.jpg" alt="Friends"/>
                                                 <h6>Michael Mattas</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-2.jpg" alt="Friends"/>
                                                 <h6>Jeffrey Hische</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-3.jpg" alt="Friends"/>
                                                 <h6>Sandra Helmwit</h6>
                                                 <div class="friends-social-media">Twitter</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-4.jpg" alt="Friends"/>
                                                 <h6>Matt Nickerson</h6>
                                                 <div class="friends-social-media">Twitter</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-5.jpg" alt="Friends"/>
                                                 <h6>Gary Hustwit</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-6.jpg" alt="Friends"/>
                                                 <h6>Enrique Allen</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-7.jpg" alt="Friends"/>
                                                 <h6>Andrew Smith</h6>
                                                 <div class="friends-social-media">Twitter</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-8.jpg" alt="Friends"/>
                                                 <h6>Toby Winters</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-9.jpg" alt="Friends"/>
                                                 <h6>Matt Chewney</h6>
                                                 <div class="friends-social-media">Twitter</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-10.jpg" alt="Friends"/>
                                                 <h6>Jane Doe</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-11.jpg" alt="Friends"/>
                                                 <h6>Karen Smith</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-12.jpg" alt="Friends"/>
                                                 <h6>Sara West</h6>
                                                 <div class="friends-social-media">Twitter</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-13.jpg" alt="Friends"/>
                                                 <h6>Jennifer Doe</h6>
                                                 <div class="friends-social-media">Twitter</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-14.jpg" alt="Friends"/>
                                                 <h6>Suzie Smith</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                             <li>
                                                 <i class="fas fa-check-circle"></i>
                                                 <img src="assets/images/demo/user-15.jpg" alt="Friends"/>
                                                 <h6>Megan Marks</h6>
                                                 <div class="friends-social-media">Facebook</div>
                                             </li>
                                         </ul>
                                         <div class="clearfix"></div>
                                     </div>*/}
                                  </form>   
                              
                             </div>
                             
                             {/*<div class="registration-genres-step">
                                 <h5>Pick your preferred Genres:</h5>
                                 <ul class="registration-genres-choice">
                                     <li class="active">
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/drama.png" alt="Drama"/>
                                         <h6>Drama</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/comedy.png" alt="Comedy"/>
                                         <h6>Comedy</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/action.png" alt="Action"/>
                                         <h6>Action</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/romance.png" alt="Romance"/>
                                         <h6>Romance</h6>
                                     </li>
                                     <li class="active">
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/horror.png" alt="Horror"/>
                                         <h6>Horror</h6>
                                     </li>
                                     <li class="active">
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/fantasy.png" alt="Fantasy"/>
                                         <h6>Fantasy</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/sci-fi.png" alt="Sci-Fi"/>
                                         <h6>Sci-Fi</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/thriller.png" alt="Thriller"/>
                                         <h6>Thriller</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/western.png" alt="Western"/>
                                         <h6>Western</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/adventure.png" alt="Adventure"/>
                                         <h6>Adventure</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/animation.png" alt="Animation"/>
                                         <h6>Animation</h6>
                                     </li>
                                     <li>
                                         <i class="fas fa-check-circle"></i>
                                         <img src="assets/images/genres/documentary.png" alt="Documentary"/>
                                         <h6>Documentary</h6>
                                     </li>
                                 </ul>
                                 <div class="clearfix"></div>
                             </div>*/}
                             
                             <div class="registration-step-final-footer">
                                <NavLink to="/signup-welcome" class="btn btn-green-pro">Finish</NavLink>
                                <NavLink to="/signup-welcome" class="btn">Skip This Step</NavLink>
                             </div>
                             
                         </div>
                        
                         
                     </div>
                 
             </div>
         </div>
         
     </div>
     
     <footer id="footer-pro">
         <div class="container">
             <div class="row">
                 <div class="col-md">
                     <div class="copyright-text-pro">&copy; Copyright 2021 <a href="https://getspreebie.com/" target="_blank">Spreebie, Inc</a>. All Rights Reserved</div>
                 </div>
                 <div class="col-md">
                    <ul class="social-icons-pro">
                        <li class="facebook-color"><a href="https://web.facebook.com/spreebieapp/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="twitter-color"><a href="https://twitter.com/thabodavidklass?lang=en" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li class="youtube-color"><a href="https://www.youtube.com/channel/UCv8WK-BgICp2Z4NaSuWJ7TA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li class="vimeo-color"><a href="https://vimeo.com/user52673013" target="_blank"><i class="fab fa-vimeo-v"></i></a></li>
                    </ul>
                </div>`
             </div>
         </div>
     </footer>
     
     <a href="#0" id="pro-scroll-top"><i class="fas fa-chevron-up"></i></a>
     
     
     
     <div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true">
                                <button type="button" class="close float-close-pro" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header-pro">
                                            <h2>Welcome Back</h2>
                                            <h6 id="loginFormMessage">Sign in to your account to continue using RobberBaron</h6>
                                        </div>
                                        <div class="modal-body-pro social-login-modal-body-pro">
                                            <div class="registration-social-login-container">
                                                <form onSubmit={this.handleSubmit.bind(this, dispatch)}>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="email" placeholder="Username" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" id="password" placeholder="Password" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-green-pro btn-display-block" id="signInButton">Sign In</button>
                                                    </div>
                                                    <div class="container-fluid">
                                                        <div class="row no-gutters">
                                                        <div class="col checkbox-remember-pro"><input type="checkbox" id="checkbox-remember"/><label for="checkbox-remember" class="col-form-label">Remember me</label></div>
                                                        <div class="col forgot-your-password"><a href="#!" onClick={this.handleClick} type="submit" id="forgotPassword">Forgot your password?</a></div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="registration-social-login-or"></div>
                                            </div>
                                            <div class="registration-social-login-options">
                                                <br />
                                                <h6>Watch premium content from your favorite creators</h6>
                                                <br />
                                                <img src="assets/images/logo_big.png" />
                                                {/*<h6>Sign in with your social account</h6>
                                                <div class="social-icon-login facebook-color" onClick={this.handleClick.bind(this, dispatch)} type="submit" id="facebookSignIn"><i class="fab fa-facebook-f"></i> Facebook</div>
                                                <div class="social-icon-login twitter-color"><i class="fab fa-twitter"></i> Twitter</div>
                                                <div class="social-icon-login google-color" onClick={this.handleClick.bind(this, dispatch)} type="submit" id="googleSignIn"><i class="fab fa-google-plus-g"></i> Google</div>*/}
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
        )
    }}
    </Consumer>
)
    }
}

export default withRouter(SignUpStep4);