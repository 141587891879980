/// The Notification class
export default class Notification {
    /// A private holder for the key
    key;
    
    /// A private holder for the creation timestamp
    creationAt;
    
    /// A private holder for the the user who created the Kascade
    robID;

    // The user name
    robOwnerID;

    // The rob ID
    robTitle;

    /// A private holder for the update timestamp
    updatedAt;
    
    /// A constuctor that takes the key and a dictionary that contains
    /// kascade data
    constructor(key, creationAt, robID, robOwnerID, robTitle, updatedAt) {
        this.key = key;
        this.creationAt = creationAt;
        this.robID = robID;
        this.robOwnerID = robOwnerID;
        this.robTitle = robTitle;
        this.updatedAt = updatedAt;
    }
}
