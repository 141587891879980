/// The Rob class
export default class Rob {
    /// A private holder for the key
    key;
    
    /// A private holder for the comment
    createdAt;
    
    /// A private holder for the creation timestamp
    description;
    
    /// A private holder for the update timestamp
    genre;
    
    /// A private holder for the the user who created the Kascade
    robID;

    // The user name
    screenshotURL;

    // The rob ID
    tags;

    // The rob ID
    title;

    // The rob ID
    updateAt;

    // The rob ID
    videoURL;

    ownerID = '';

    views = 0;
    
    /// A constuctor that takes the key and a dictionary that contains
    /// kascade data
    constructor(key, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID = '', views = 0) {
        this.key = key;
        this.createdAt = createdAt;
        this.description = description;
        this.genre = genre;
        this.robID = robID;
        this.screenshotURL = screenshotURL;
        this.tags = tags;
        this.title = title;
        this.updateAt = updateAt;
        this.videoURL = videoURL;
        this.ownerID = ownerID;
        this.views = views;
    }
}