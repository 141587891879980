import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import firebase from '../../firebase';
import Notification from '../classes/Notification';
import Rob from '../classes/Rob';
import { loadStripe } from '@stripe/stripe-js';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');
require('firebase/auth');
const https = require('https')

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class DashboardAccountPayment extends React.Component  {
    state = {
        userID: '',
        isLoggedIn: false,
        firstName: '',
        lastName: '',
        email: '',
        fullName: '',
        dataLoaded: false,
        profilePictureFileName: '',
        currentUserFullName: '',
        searchText: '',
        subs: [],
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false,
        plan: "Free",
        getPayPalAuthToken: false,
        payPalAccessToken: '',
        getPayPalSubscriptionStatusCalled: false,
        subscriptionID: '',
        premiumSubscriptionStatus: '',
        premiumSubscriptionNextBillingTime: '',
        isStripeSubscriptionActive: false,
        stripeContentCreatorID: null,
        stripeSubscriptionID: null,
        revenue: 0,
        revenueLoaded: false
    }
    
    componentDidMount () {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/jquery-asRange.min.js");
        appendScript("assets/js/circle-progress.min.js");
        appendScript("assets/js/afterglow.min.js");
        appendScript("assets/js/script.js");
        appendScript("assets/js/script-dashboard.js");
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: ''
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    handleChange = (e) => {
        //e.preventDefault()

        this.setState({
            [e.target.id]: e.target.value
        })

        if (e.target.id === 'searchText') {            
            this.props.history.push('/dashboard-search/' + e.target.value);
        }
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                })

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }

    updateSubscriptionData = (subscriptionID, userID) => {
        const userRef = firebase.firestore().collection('users').doc(userID)

        userRef
            .update({
                plan: "Starter",
                subscriptionID,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('Update compvare.')
            })
    }

    createPremiumSubscription = (subscriptionID, userID) => {
        firebase
            .firestore()
            .collection('premiumSubscriptions')
            .doc(userID)
            .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                subscriptionID,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                userID
            }).then(() => {
                this.setState({
                    ...this,
                    plan: "Starter",
                    dataLoaded: false
                });
            });
    }

    updatePlan = (userID, plan) => {
        const userRef = firebase.firestore().collection('users').doc(userID)

        userRef
            .update({
                plan,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('Update compvare.')
            })
    }

    getStripeSubscriptionStatus = (userID) => {
        let subscriptionsRef = firebase.firestore().collection('users').doc(userID).collection('subscriptions');
            
        subscriptionsRef.get()
            .then((snapshot) => {
                var notifications = [];

                snapshot.forEach((doc) => {
                    let { status, current_period_end } = doc.data();
                    if (status === 'active') {
                        this.setState({
                            ...this,
                            stripeSubscriptionID: doc.id,
                            isStripeSubscriptionActive: true,
                            premiumSubscriptionNextBillingTime: current_period_end.seconds
                        });
                    }
                });
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    sendToCheckout = async (e) => {
        e.preventDefault();
              // had to update firebase.firestore() to firebase.default.firestore() (from stripe firebase extension docs)

        $('#payToSubscribeButton').text('Please Wait');
        $('#payToSubscribeButton').prop('disabled', true);

        if (this.state.userID) {
            firebase
                .firestore()
                .collection('users')
                .doc(this.state.userID)
                .collection('checkout_sessions')
                .add({
                    price: 'price_1In69mEhuWeYDq5DLeeVrpUg', // todo price Id from your products price in the Stripe Dashboard
                    success_url: window.location.origin, // return user to this screen on successful purchase
                    cancel_url: window.location.origin // return user to this screen on failed purchase
                })
                .then((docRef) => {
                    // Wait for the checkoutSession to get attached by the extension
                    docRef.onSnapshot(async (snap) => {
                    const { error, sessionId } = snap.data();
                    if (error) {
                        // Show an error to your customer and inspect
                        // your Cloud Function logs in the Firebase console.
                        alert(`An error occurred: ${error.message}`);
                    }

                    if (sessionId) {
                        // We have a session, let's redirect to Checkout
                        // Init Stripe
                        const stripe = await loadStripe(
                        'pk_live_Nnl12K4jTmYq564NWV0aPUCT' // todo enter your public stripe key here
                        );
                        console.log(`redirecting`);
                        await stripe.redirectToCheckout({ sessionId });
                    }
                    });
                });
        }
    }

    getNumberOfUsers = () => {
        let robs = firebase.firestore().collection('users');

        robs.get()
            .then((snapshot) => {

                console.log('Number of users: ', snapshot.size);

                snapshot.forEach((doc) => {
                    console.log('Creator data: ', doc.data());
                })
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNumberOfUsersAsync = async () => {
        let usersRef = firebase.firestore().collection('users');
        let users = await usersRef.get();
        let size = users.docs.length;

        return size;
    }

    getUserSubscriberCount = async (userID) => {
        const userRef = firebase.firestore().collection('users').doc(userID);
        let user = await userRef.get();
        let subscriberCount = user.data().subscriberCount;

        if (subscriberCount) {
            return subscriberCount;
        }

        return 0;
    }

    getRevenueAsync = async (userID) => {
        let withdrawnProceeds = await this.getWithdrawnProceeds(userID);
        let robTime = await this.getFirstRobTime(userID)
        let daysElapsed = ((Date.now() / 1000) - robTime) / 86400;
        let monthsElapsed = daysElapsed / 30;
        let numberOfUsers = await this.getNumberOfUsersAsync();
        let payingCustomers = numberOfUsers * 0.8;
        let pot =  payingCustomers * 0.375 * 5.99;

        let payingCustomersMeanSubscribers = payingCustomers / (0.05 * numberOfUsers)

        let getUserSubscriberCount = await this.getUserSubscriberCount(userID);

        let revenue = (((getUserSubscriberCount / (payingCustomersMeanSubscribers * payingCustomers)) * pot) * monthsElapsed) - withdrawnProceeds;

        this.updateProceedsData(revenue.toFixed(2), userID);

        this.setState({
            ...this,
            revenue,
            revenueLoaded: true
        });
    }

    updateProceedsData = (revenue, userID) => {
        const userRef = firebase.firestore().collection('users').doc(userID)

        userRef
            .update({
                proceeds: revenue,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('Update complete.')
            })
    }

    getFirstRobTime = async (userID) => {
        var robRef = firebase.firestore().collection('robs').doc(userID).collection('data');

        let robs = await robRef.get();

        let robArray = [];

        robs.forEach((doc) => {
            const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL } = doc.data();
            var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL)
            robArray.push(robObject);

            robArray.sort(this.compare)
        });

        if (robArray.length > 0) {
            let firstRobCreatedAt = robArray.slice(-1)[0].createdAt;

            return firstRobCreatedAt.seconds;
        }

        return Date.now() / 1000;
    }

    compare = (a, b) => {
        if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
        if (b.createdAt.toDate() < a.createdAt.toDate()) return -1;
      
        return 0;
    }

    handleClick = (e) => {
        e.preventDefault();

        if (e.target.id == "withdrawFunds") {
            if (this.state.revenue < 100) {
                $('#withdrawalMessage').css('color', '#FF0000');
                $('#withdrawalMessage').text("Sorry. Only widthdrawals greater than $100 are processed.");
            } else {
                this.withdrawFunds(this.state.userID, this.state.revenue);
            }
        }
    }

    withdrawFunds = (userID, withdrawalAmount) => {
        var robUserRef = firebase.firestore().collection('withdrawals').doc(userID).collection('data').doc();

        robUserRef
            .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                userID,
                withdrawalAmount,
                updateAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                firebase.firestore()
                    .collection('adminWithdrawals')
                    .add({
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        userID,
                        withdrawalAmount,
                        dealtWith: false,
                        updateAt: firebase.firestore.FieldValue.serverTimestamp()
                    });
                
                $('#withdrawalMessage').css('color', '#00FF00');
                $('#withdrawalMessage').text("Widthdral successful.");

                this.setState({
                    ...this.state,
                    revenue: 0
                });
            })
    }

    getWithdrawnProceeds = async (userID) => {
        var withdrawalsRef = firebase.firestore().collection('withdrawals').doc(userID).collection('data');

        let Widthdrals = await withdrawalsRef.get();

        let amount = 0;

        Widthdrals.forEach((doc) => {
            const { withdrawalAmount } = doc.data();

            amount = amount + withdrawalAmount;
        });

        return amount;
    }
    
    render() {
        let stripeConnectURL = '';
        let stripeCancelURL = 'https://robberbaronpayments.com/cancel?stripeSubscriptionID=' + this.state.stripeSubscriptionID + '&userID=' + this.state.userID;
        var localBillingTime = '';
        let connectToStripeButton = <div><h6>Connection Status: </h6><h6 id="connectToStripeButton">Connected to Stripe.</h6></div>;
        $('#connectToStripeButton').css('color', '#00FF00');
        if (this.state.premiumSubscriptionNextBillingTime !== '') {
            localBillingTime = new Date(this.state.premiumSubscriptionNextBillingTime * 1000).toLocaleDateString("en-US")
        }

        var payPalSection = <div></div>;
        var planFeatures = <div><h5>Plan Features:</h5><ul><li>Post Videos</li><li>Works on any Device</li><li>Watch General Content</li></ul></div>
        var planText = "STARTER";
        var planAmount = "5";
        var cancelSubscriptionButton = <div><p><b>Cancel Subscription: </b>Click the button below to cancel your RobberBaron (<b>Spreebie, Inc</b>) subscription immediately.</p><a href={stripeCancelURL} class="stripe-connect slate"><span>Cancel Sub - </span></a></div>;
        var billingDate = <div class="current-plan-billing">Your next renewal date will be <b>{ localBillingTime }</b></div>

        if (!this.state.isStripeSubscriptionActive) {
            planText = "FREE";
            planAmount = "0";
            payPalSection = <div><p>Subscribe now for $6 per month to start viewing premium content from your favorite creator.</p><button class="btn btn-green-pro" id="payToSubscribeButton">Pay To Subscribe</button></div>;
            planFeatures = <div><h5>Plan Features:</h5><ul><li>Post Videos</li></ul></div>;
            cancelSubscriptionButton = <p></p>;
            billingDate = '';
        }

        return (
            <Consumer>
                {value => {
                    const { isLoggedIn, userID, dispatch } = value;
        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));


        if (!persistedState || !persistedState.userID || persistedState.userID === '') {
            this.props.history.push('/');
        } else {
            if (userID === persistedState.userID) {
                stripeConnectURL = 'https://robberbaronpayments.com/authorize?contentCreatorUserID=' + userID;
                if (!this.state.stripeContentCreatorID) {
                    connectToStripeButton = <div><p><b>Connect with Stripe: </b>Click the button below to connect your RobberBaron (<b>Spreebie, Inc</b>) and Stripe accounts in order to receive payments for your content.</p><a href={stripeConnectURL} class="stripe-connect slate"><span>Connect with</span></a></div>;
                }

                if (!this.state.subsLoaded) {    
                    this.getSubs(userID)
                } else {
                    if (!this.state.notificationsLoaded) {
                        this.getNotifications(this.state.subs);
                    }
                }

                if (!this.state.revenueLoaded) {
                    this.getRevenueAsync(userID);
                }
            }
            
            if (!persistedState || !persistedState.userID || persistedState.userID === '') {
                this.props.history.push('/');
            }
            

            if (persistedState.userID !=='' && !this.state.dataLoaded) {
                this.getStripeSubscriptionStatus(persistedState.userID);
                var userRef = firebase.firestore().collection('users').doc(persistedState.userID)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { email, firstName, lastName, fullName, profilePictureFileName, plan, subscriptionID, stripeContentCreatorID } = user.data()

                        if (persistedState && persistedState.userID && persistedState.userID !== '') {
                            this.setState({
                                ...this.state,
                                userID: persistedState.userID,
                                currentUserFullName: persistedState.fullName
                            })
                        }

                        if (!this.state.dataLoaded) {
                            this.setState({
                                ...this.state,
                                email,
                                firstName,
                                lastName,
                                fullName,
                                profilePictureFileName,
                                subscriptionID,
                                dataLoaded: true,
                                plan,
                                stripeContentCreatorID
                            });
                        }
                    } else {
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            }
        }

        return (
            <div className="App">
                <div id="sidebar-bg">
			
            <header id="videohead-pro" class="sticky-header">
            <div id="video-logo-background"><Link to="/dashboard"><img src="assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                  
                  <div id="video-search-header">
                      <div id="search-icon-more"></div>
                      <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                  </div>
                  
                  <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                  
                  
                  <div id="header-user-profile">
                      <div id="header-user-profile-click" class="noselect">
                          <img src={this.state.profilePictureFileName} />
                         <div id="header-username">{this.state.currentUserFullName}</div><i class="fas fa-angle-down"></i>
                      </div>
                      <div id="header-user-profile-menu">
                          <ul>
                              <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                              <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                              <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                              <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                              <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                          </ul>
                      </div>
                  </div>
                  
                  <div id="header-user-notification">
                      <div id="header-user-notification-click" class="noselect">
                          <i class="far fa-bell"></i>
                          <span class="user-notification-count">3</span>
                      </div>
                      <div id="header-user-notification-menu">
                          <h3>Notifications</h3>
                          <div id="header-notification-menu-padding">
                                  <ul id="header-user-notification-list">
                                      { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                  </ul>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                  </div>

                  <div id="header-user-create">
                        <div id="header-user-create-click" class="noselect">
                            <NavLink to="/dashboard-video-upload">
                                <img src="assets/images/create.png" alt="Create"/>
                            </NavLink>
                        </div>
                    </div>
                  
                  
                  
                  <div class="clearfix"></div>
                  
                  <nav id="mobile-navigation-pro">
                  
                  <ul id="mobile-menu-pro">
                  <li>
                            <NavLink to="/dashboard">
                                <span class="icon-Fire-Flame"></span>
                            Popular
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                Subscriptions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
                        <li>
                            <a href="#!">
                                <i class="far fa-bell"></i>
                                <span class="user-notification-count">3</span>
                            Notifications
                            </a>
                        </li>
                        </ul>
                      <div class="clearfix"></div>
                      
                      <div id="search-mobile-nav-pro">
                          <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                      </div>
                      
                  </nav>
                  
            </header>
              
              
              
              <nav id="sidebar-nav">
                  <ul id="vertical-sidebar-nav" class="sf-menu">
                  <li class="normal-item-pro">  
                        <NavLink to="/dashboard">
                              <span class="icon-Fire-Flame"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
      
                  </ul>
                      <div class="clearfix"></div>
              </nav>
          
              <main id="col-main">
                  
                  <div class="dashboard-container">
                      
                      <ul class="dashboard-sub-menu">
                          <li><NavLink to="/dashboard-account">Account Settings</NavLink></li>
                          <li class="current"><NavLink to="/dashboard-account-payment">Payment Info</NavLink></li>
                      </ul>
                      
                      <h5>Current Subscription Plan</h5>
                      
                      <div class="current-plan-account">
                          
                          <div class="row">
                              <div class="col-md">
                                  <h4>{ planText}</h4>
                                  <h2><sup>$</sup>{ planAmount }<span> / month</span></h2>
                              </div>
                              <div class="col-md">
                                  { planFeatures }
                              </div>
                              <div class="col-md">
                                  <form onSubmit={this.sendToCheckout.bind(this)}>
                                  { payPalSection }
                                  </form>
                              </div>
                              <div class="col-md">
                                  
                              </div>
                          </div>
                          
                          { billingDate }
                          <div class="clearfix"></div>
                      </div>
                      
                      <hr/>
                      
                      { cancelSubscriptionButton }
                      <br/>

                      <h5>Get Paid For Your Content:</h5>
                      
                      <div class="current-plan-account">
                          <div>
                            {connectToStripeButton}
                            <br />
                            <br />
                          </div>
                          <br />
                          <div class="row">
                              <div class="col-md">
                                  <h4>CONTENT PROCEEDS</h4>
                                  <h2><sup>$</sup>{this.state.revenue.toFixed(2)}</h2>
                              </div>
                              <div class="col-md">
                              </div>
                              <div class="col-md">
                                <p><a href="#!" class="btn" id="withdrawFunds" onClick={this.handleClick} type="submit">Withdraw Funds</a></p>
                                <div><h6 id="withdrawalMessage"></h6></div>
                              </div>
                              <div class="col-md">
                                
                              </div>
                          </div>
                          
                          <div class="current-plan-billing"></div>
                          <div class="clearfix"></div>
                      </div>
                      
                      <hr/>
                  </div>
              </main>
              
              
              </div>
            </div>
        )
        }}
            </Consumer>
        )
}
} 

export default withRouter(DashboardAccountPayment)