import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import HomeNavbar from '../layout/HomeNavBar';
import firebase from '../../firebase';
import { Consumer } from '../../context';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class SignUpStep2 extends React.Component  {
    state = {
        email: '',
        password: '',
        isLoggedIn: false,
        userID: '',
        fullName: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        loginEmail: '',
        loginPassword: '',
        userID: '',
        profilePictureFileName: '',
        agreeWithPolicies: false,
        plan: 'Starter'
    }

    componentWillMount() {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/script.js");

        if ((this.props.location.signUpProps)) {
            console.log(this.props.location.signUpProps.plan);

            this.setState({
                ...this.state,
                plan: this.props.location.signUpProps.plan
            });
        }
    }

    handleSubmit = (dispatch, e) => {
        e.preventDefault()

        if (e.target.id === 'loginForm') {
            $('#signInButton').text('Please Wait');
            $('#signInButton').prop('disabled', true);
            
			firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                .then((user) => {
                    var user = firebase.auth().currentUser;
                    var userRef = firebase.firestore().collection('users').doc(user.uid)

                    userRef.get().then((user) => {
                        if (user.exists) {
                            const { fullName, userID } = user.data();

                            dispatch({
                                type: 'USER_STATE',
                                payload: {
                                    isLoggedIn: true,
                                    userID,
                                    fullName
                                }
                            });

                            this.setState({
                                ...this.state,
                                isLoggedIn: true,
                                userID,
                                fullName
                            });              
                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    }).catch(function(error) {
                        console.log("Error getting document:", error);
                    });
                }, function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log('Error: ' + error.code + ' ', errorMessage);

                    if (errorCode === "auth/user-not-found") {
                        $('#loginFormMessage').text('The email you entered is wrong. Please try again.');
                    } else if (errorCode === "auth/wrong-password") {
                        $('#loginFormMessage').text('The password is wrong. Please try again.');
                    } else {
                        $('#loginFormMessage').text('There was an error logging. Please try again.');
                    }

                    $('#signInButton').text('Sign In');
                    $('#signInButton').prop('disabled', false);
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                    });
		} else {
            if (this.state.agreeWithPolicies) {
                if (this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '' && this.state.password !== '' && this.state.confirmPassword != '') {
                    if (this.state.password === this.state.confirmPassword) {
                        console.log("Sign up button Clicked!");
                        if (this.state.plan === 'Free') {
                            if (this.state.email !== '' && this.state.password != '' && this.state.firstName != '' && this.state.lastName != '' && this.state.password != '') {
                                $('#createButton').text('Please Wait');
                                $('#createButton').prop('disabled', true);

                                firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
                                    .then((user) => {
                                        var user = firebase.auth().currentUser;
                                        this.setUserData(this.state.email, this.state.firstName, this.state.lastName, this.state.profilePictureFileName, user.uid, this.state.plan, '', dispatch);
                                    }, function(error) {
                                        // Handle Errors here.
                                        var errorCode = error.code;
                                        var errorMessage = error.message;

                                        $('#createButton').text('Sign Up');
                                        $('#createButton').prop('disabled', false);
                                    });
                            } else {
                                $('#signupFormMessage').css('color', '#FF0000');
                                $('#signupFormMessage').text('Some fields seem to be missing. Please fill them in.');
                            }
                        } else {
                            if (this.state.email !== '' && this.state.password != '' && this.state.firstName != '' && this.state.lastName != '' && this.state.password != '') {
                                //if (this.state.readyForSignup) {
                                    console.log("Section 1");
                                    $('#createButton').text('Please Wait');
                                    $('#createButton').prop('disabled', true);

                                    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
                                        .then((user) => {
                                            console.log("Section 2");
                                            var user = firebase.auth().currentUser;
                                            this.setUserData(this.state.email, this.state.firstName, this.state.lastName, this.state.profilePictureFileName, user.uid, this.state.plan, '', dispatch);
                                        }, function(error) {
                                            // Handle Errors here.
                                            var errorCode = error.code;
                                            var errorMessage = error.message;

                                            $('#createButton').text('Sign Up');
                                            $('#createButton').prop('disabled', false);
                                        });
                            } else {
                                $('#signupFormMessage').css('color', '#FF0000');
                                $('#signupFormMessage').text('Some fields seem to be missing. Please fill them in.');
                            }
                        }
                    } else {
                        $('#signupFormMessage').css('color', '#FF0000');
                        $('#signupFormMessage').text('The passwords you entered in the fields are not the same.');
                    }
                } else {
                    $('#signupFormMessage').css('color', '#FF0000');
                    $('#signupFormMessage').text('Please fill all the fields.');
                }
            } else {
                $('#signupFormMessage').css('color', '#FF0000');
                $('#signupFormMessage').text('Please click the checkbox to indicatate that you agree to our Terms of Use, Privacy Policy and Cookie Policy.');
            }
        }
    }

    setUserData = (email, firstName, lastName, profilePictureFileName, userID, plan, subscriptionID, dispatch) => {
        var fullName = firstName + ' ' + lastName;
        var firstvarterCapitalized = fullName.charAt(0).toUpperCase();

        firebase
            .firestore()
            .collection('users')
            .doc(userID)
            .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                email,
                firstName,
                lastName,
                fullName,
                profilePictureFileName,
                searchKey: firstvarterCapitalized,
                authToken: '',
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                userID,
                plan,
                subscriptionID
            })
            .then(() => {
                var userRef = firebase.firestore().collection('users').doc(userID)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data()

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        }); 
                        
                        this.props.history.push({
                            pathname: '/signup-step3',
                            signUpProps: {
                                plan: this.state.plan,
                                firstName: this.state.firstName,
                                lastName: this.state.lastName,
                                email: this.state.email,
                                password: this.state.password,
                                userID,
                                user
                            }
                        });
                    } else {
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                    $('#createButton').text('Sign Up');
                    $('#createButton').prop('disabled', false);
                });
            })
    }

    handleChange = (e) => {
        console.log(e.target.id)
        if (e.target.id === "agreeWithPolicies") {
            console.log(e.target.checked)
            this.setState({
                agreeWithPolicies: e.target.checked
            });
        } else {
            this.setState({
                [e.target.id]: e.target.value
            })
        }

        $('#loginFormMessage').css('color', '#000000');
        $('#loginFormMessage').text('Sign in to your account to continue using RobberBaron');

        $('#signupFormMessage').css('color', '#000000');
        $('#signupFormMessage').text('');
    }

    handleClick = (e) => {
        e.preventDefault();

        if (e.target.id === 'facebookSignIn') { 
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user);
                console.log(token);
                this.setSocialUserData(user.email, user.uid, user.displayName, token);
                // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
        } else if (e.target.id === 'forgotPassword') {
            if (this.state.email === '') {
                $('#loginFormMessage').text('Please fill in your email.');
                    
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            } else {
                var auth = firebase.auth();
                var emailAddress = this.state.email

                auth.sendPasswordResetEmail(emailAddress).then(function() {
                    $('#loginFormMessage').text('Your password has been reset. Please check your email.');
                }).catch(function(error) {
                    $('#loginFormMessage').text('There was an error reseting your password. Please try again.');
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                });
            }
        } else {
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user);
                this.setSocialUserData(user.email, user.uid, user.displayName, token);
                // ...
              }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
        }
    }
    
    render() {
        console.log(this.state);
        const { isLoggedIn } = this.state;

        if (isLoggedIn) {
            $(".float-close-pro").click();
            this.props.history.push('/dashboard');
        }

        return (
            <Consumer>
                {value => {
                    const { dispatch } = value;
        return (
            <div className="App">
                <HomeNavbar />
                <div id="content-pro">
			
            <div className="container">
                 <div className="centered-headings-pro pricing-plans-headings">
                     <h6>For one low monthly price</h6>
                     <h1>Instantly watch Premium Content From Your Favorite Creator</h1>
                 </div>
         </div>
         
         <div class="container">
             <ul id="registration-steps-pro">
                 <li>
                    <NavLink to="/signup-step1">
                         <div class="registration-step-circle-icon">01</div>
                         <div class="registration-step-number">Step 1</div>
                         <h5 class="registration-step-title">Choose your plan</h5>
                    </NavLink>
                 </li>
                 <li class="current-menu-item">
                    <NavLink to="/signup-step2">
                         <div class="registration-step-circle-icon">02</div>
                         <div class="registration-step-number">Step 2</div>
                         <h5 class="registration-step-title">Create your account</h5>
                    </NavLink>
                 </li>
                 <li>
                    <NavLink to="/signup-step3">
                         <div class="registration-step-circle-icon">03</div>
                         <div class="registration-step-number">Step 3</div>
                         <h5 class="registration-step-title">Billing information</h5>
                    </NavLink>
                 </li>
             </ul>
             <div class="clearfix"></div>
         </div>
         
         
         <div id="pricing-plans-background-image">
             <div class="container">
                     <div class="registration-steps-page-container">
                         
                        <form class="registration-steps-form" onSubmit={this.handleSubmit.bind(this, dispatch)}>
                          
                          <div class="registration-social-login-container">

                                <div class="form-group">
                                    <div class="row">
                                     <div class="col">
                                     <label for="first-name" class="col-form-label">First Name</label>
                                    <input type="text" class="form-control" id="firstName" placeholder="John" onChange={this.handleChange}/>
                                     </div>
                                     <div class="col">
                                     <label for="last-name" class="col-form-label">Last Name</label>
                                    <input type="text" class="form-control" id="lastName" placeholder="Doe" onChange={this.handleChange}/>
                                     </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label for="email" class="col-form-label">Email</label>
                                    <input type="email" class="form-control" id="email" placeholder="jondoe@gmail.com" onChange={this.handleChange}/>
                                </div>
                                <div class="form-group">
                                  <div class="row">
                                     <div class="col">
                                        <label for="password" class="col-form-label">Password</label>
                                          <input type="password" class="form-control" id="password" placeholder="&middot;&middot;&middot;&middot;&middot;&middot;" onChange={this.handleChange}/>
                                     </div>
                                     <div class="col">
                                        <label for="confirm-password" class="col-form-label">&nbsp;</label>
                                          <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password" onChange={this.handleChange}/>
                                     </div>
                                  </div>
                                </div>
                              
                              <div class="registration-social-login-or"></div>
                              
                          </div>
                          
                          <div class="registration-social-login-options">
                          <br />
                            <h6>Watch premium content from your favorite creators</h6>
                            <br />
                            <img src="assets/images/logo_big.png" />
                          </div>
                          
                          <div class="clearfix"></div>
                            <div class="form-group last-form-group-continue">
                                <h6 id="signupFormMessage"></h6>
                                <button id="createButton" class="btn btn-green-pro">Create</button>
                              <span class="checkbox-remember-pro">
                              <input
                                type="checkbox"
                                id="agreeWithPolicies"
                                checked={this.state.agreeWithPolicies}
                                onChange={this.handleChange}
                                /><label for="checkbox-terms" class="col-form-label"/>By clicking "Create", you agree to our <a href="https://openbeacon.biz/1038-2/" target="_blank">Terms of Use</a>, 
                                <a href="https://openbeacon.biz/robberbaron-privacy-policy/" target="_blank"> Privacy Policy</a> and <a href="https://openbeacon.biz/robberbaron-cookie-policy/" target="_blank">Cookie Policy</a>.</span>
                              <div class="clearfix"></div>
                            </div>
                        </form>
                         
                     </div>
                 
             </div>
         </div>
         
     </div>
     
     <footer id="footer-pro">
         <div class="container">
             <div class="row">
                 <div class="col-md">
                     <div class="copyright-text-pro">&copy; Copyright 2021 <a href="https://getspreebie.com/" target="_blank">Spreebie, Inc</a>. All Rights Reserved</div>
                 </div>
                 <div class="col-md">
                    <ul class="social-icons-pro">
                        <li class="facebook-color"><a href="https://web.facebook.com/spreebieapp/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="twitter-color"><a href="https://twitter.com/thabodavidklass?lang=en" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li class="youtube-color"><a href="https://www.youtube.com/channel/UCv8WK-BgICp2Z4NaSuWJ7TA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li class="vimeo-color"><a href="https://vimeo.com/user52673013" target="_blank"><i class="fab fa-vimeo-v"></i></a></li>
                    </ul>
                </div>
             </div>
         </div>
     </footer>
     
     <a href="#0" id="pro-scroll-top"><i class="fas fa-chevron-up"></i></a>
     
     
     
     <div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true">
                                <button type="button" class="close float-close-pro" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header-pro">
                                            <h2>Welcome Back</h2>
                                            <h6 id="loginFormMessage">Sign in to your account to continue using RobberBaron</h6>
                                        </div>
                                        <div class="modal-body-pro social-login-modal-body-pro">
                                            <div class="registration-social-login-container">
                                                <form onSubmit={this.handleSubmit.bind(this, dispatch)} id="loginForm">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="email" placeholder="Username" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" id="password" placeholder="Password" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-green-pro btn-display-block" id="signInButton">Sign In</button>
                                                    </div>
                                                    <div class="container-fluid">
                                                        <div class="row no-gutters">
                                                        <div class="col checkbox-remember-pro"><input type="checkbox" id="checkbox-remember"/><label for="checkbox-remember" class="col-form-label">Remember me</label></div>
                                                        <div class="col forgot-your-password"><a href="#!" onClick={this.handleClick} type="submit" id="forgotPassword">Forgot your password?</a></div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="registration-social-login-or"></div>
                                            </div>
                                            <div class="registration-social-login-options">
                                                <br />
                                                <h6>Watch premium content from your favorite creators</h6>
                                                <br />
                                                <img src="assets/images/logo_big.png" />
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
        )
    }}
    </Consumer>
)
    }
}

export default withRouter(SignUpStep2);