/// The User class
export default class User {
    /// A private holder for the key
    key;
    
    /// A private holder for the comment
    createdAt;
    
    /// A private holder for the creation timestamp
    email;
    
    /// A private holder for the update timestamp
    firstName;
    
    /// A private holder for the the user who created the Kascade
    fullName;

    // The user name
    lastName;

    // The rob ID
    profilePictureFileName;

    // The rob ID
    searchKey;

    // The rob ID
    updateAt;

    // The rob ID
    userID;
    
    /// A constuctor that takes the key and a dictionary that contains
    /// kascade data
    constructor(key, createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID) {
        this.key = key;
        this.createdAt = createdAt;
        this.email = email;
        this.firstName = firstName;
        this.fullName = fullName;
        this.lastName = lastName;
        this.profilePictureFileName = profilePictureFileName;
        this.searchKey = searchKey;
        this.updateAt = updateAt;
        this.userID = userID;
    }
}