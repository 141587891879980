import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomeNavbar from './components/layout/HomeNavBar';
import Index from './components/pages/Index';
import FAQs from './components/pages/FAQs';
import Dashboard from './components/pages/Dashboard';
import SignUpStep1 from './components/pages/SignUpStep1';
import DashboardAccount from './components/pages/DashboardAccount';
import DashboardAccountPayment from './components/pages/DashboardAccountPayment';
import DashboardProfile from './components/pages/DashboardProfile';
import { Provider } from './context';
import './App.css';

import DashboardFavorites from './components/pages/DashboardFavorites';
import DashboardSubs from './components/pages/DashboardSubs';
import DashboardNewReleases from './components/pages/DashboardNewReleases';
import DashboardMine from './components/pages/DashboardMine';
import DashboardMovieProfile from './components/pages/DashboardMovieProfile';
import SignUpStep2 from './components/pages/SignUpStep2';
import SignUpStep3 from './components/pages/SignUpStep3';
import SignUpStep4 from './components/pages/SignUpStep4';
import SignUpWelcome from './components/pages/SignUpWelcome';
import DashboardVideoUpload from './components/pages/DashboardVideoUpload';
import DashboardEditVideo from './components/pages/DashboardEditVideo';
import DashboardSearch from './components/pages/DashboardSearch';

class App extends React.Component {
    render() {
        return (
            <Provider>
                <Router>
                    <React.Fragment>
                        <Switch>
                            <Route exact path="/" component={Index} />
                            <Route path='/dashboard' component={Dashboard} />
                            <Route path='/signup-step1' component={SignUpStep1} />
                            <Route path='/faqs' component={FAQs} />
                            <Route path='/dashboard-account' component={DashboardAccount} />
                            <Route path='/dashboard-account-payment' component={DashboardAccountPayment} />
                            <Route exact path='/dashboard-profile/:userID' component={DashboardProfile} />
                            <Route path='/dashboard-favorites' component={DashboardFavorites} />
                            <Route path='/dashboard-subs' component={DashboardSubs} />
                            <Route path='/dashboard-new-releases' component={DashboardNewReleases} />
                            <Route path='/dashboard-mine' component={DashboardMine} />
                            <Route exact path='/dashboard-movie-profile/:robID' component={DashboardMovieProfile} />
                            <Route path='/signup-step2' component={SignUpStep2} />
                            <Route path='/signup-step3' component={SignUpStep3} />
                            <Route path='/signup-step4' component={SignUpStep4} />
                            <Route path='/signup-welcome' component={SignUpWelcome} />
                            <Route path='/dashboard-video-upload' component={DashboardVideoUpload} />
                            <Route path='/dashboard-edit-video/:videoID' component={DashboardEditVideo} />
                            <Route path='/dashboard-search/:searchText' component={DashboardSearch} />
                        </Switch>
                    </React.Fragment>
                </Router>
            </Provider>
        );
    }
}

export default App;
