import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import firebase from '../../firebase';
import Rob from '../classes/Rob';
import Notification from '../classes/Notification';
require('firebase/auth');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class DashboardProfile extends React.Component  {
    state = {
        currentPage: 1,
        robsPerPage: 9,
        currentRobs: [],
        pageNumbers: [],
        currentRobsLoaded: false,
        robsLoaded: false,
        userID: '',
        profileUserID: '',
        isLoggedIn: false,
        firstName: '',
        lastName: '',
        email: '',
        fullName: '',
        dataLoaded: false,
        profilePictureFileName: '',
        currentUserProfilePictureFileName: '',
        robs: [],
        currentUserFullName: '',
        searchText: '',
        subs: [],
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false,
        myNotifications: [],
        myNotificationsLoaded: false
    }

    componentDidMount () {
        appendScript("/assets/js/libs/jquery-3.3.1.min.js");
        appendScript("/assets/js/libs/popper.min.js");
        appendScript("/assets/js/libs/bootstrap.min.js");
        appendScript("/assets/js/navigation.js");
        appendScript("/assets/js/jquery.flexslider-min.js");
        appendScript("/assets/js/jquery-asRange.min.js");
        appendScript("/assets/js/circle-progress.min.js");
        appendScript("/assets/js/afterglow.min.js");
        appendScript("/assets/js/script.js");
        appendScript("/assets/js/script-dashboard.js");

        this.setState({
            ...this.state,
            profileUserID: this.props.match.params.userID
        })
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: ''
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    compare = (a, b) => {
        if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
        if (b.createdAt.toDate() < a.createdAt.toDate()) return -1;
      
        return 0;
    }

    handleChange = (e) => {
        //e.preventDefault()

        this.setState({
            [e.target.id]: e.target.value
        })

        if (e.target.id === 'searchText') {            
            this.props.history.push('/dashboard-search/' + e.target.value);
        }
    }

    handleClick = (e) => {
        e.preventDefault();

        this.setState({
            ...this.state,
            currentRobs: [],
            currentPage: Number(e.target.id),
            currentRobsLoaded: false
        });
    }

    getUserData = (profileUserID, persistedState) => {
        var userRef = firebase.firestore().collection('users').doc(profileUserID);

        userRef.get().then((user) => {
            if (user.exists) {
                const { email, firstName, lastName, fullName, profilePictureFileName } = user.data()

                if (persistedState && persistedState.userID && persistedState.userID !== '' && !this.state.dataLoaded) {
                    this.setState({
                        ...this.state,
                        userID: persistedState.userID,
                        currentUserFullName: persistedState.fullName,
                        email,
                        firstName,
                        lastName,
                        fullName,
                        profilePictureFileName,
                        dataLoaded: true
                    })
                }
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    getCurrentUserProfilePicture = (userID) => {
        var userRef = firebase.firestore().collection('users').doc(userID)

        userRef.get().then((user) => {
            if (user.exists) {
                const { profilePictureFileName } = user.data()

                if (!this.state.profilePictureFileNameLoaded) {
                    this.setState({
                        ...this.state,
                        profilePictureFileNameLoaded: true,
                        currentUserProfilePictureFileName: profilePictureFileName
                    });
                }
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    paginate = () => {
        const { robs, currentPage, robsPerPage } = this.state;

        const indexOfLastRob = currentPage * robsPerPage;
        const indexOfFirstRob = indexOfLastRob - robsPerPage;
        const currentRobs = robs.slice(indexOfFirstRob, indexOfLastRob);

        const pageNumbers = [];

        for (var i = 1; i <= Math.ceil(this.state.robs.length / this.state.robsPerPage); i++) {
            pageNumbers.push(i);
        }

        this.setState({
            ...this.state,
            currentRobs,
            currentRobsLoaded: true,
            pageNumbers
        });
    }

    getUserRobs = (profileUserID) => {
        var robRef = firebase.firestore().collection('robs').doc(profileUserID).collection('data')

        robRef.get()
            .then((snapshot) => {
                var robs = [];
                var robIDs = [];

                snapshot.forEach((doc) => {
                    const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL } = doc.data();
                    var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL)
                    robs.push(robObject);

                    robs.sort(this.compare)
                });

                this.setState({
                    ...this.state,
                    robsLoaded: true,
                    robs
                });
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                })

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    console.log("The count is: ", count)

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }

    getMyNotifications = (creator) => {
        var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
        notificationRef.get()
            .then((snapshot) => {
                var myNotifications = [];

                snapshot.forEach((doc) => {
                    const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                    var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                    
                    myNotifications.push(notificationObject);

                    myNotifications.sort(this.compareNotifications)
                });

                this.setState({
                    ...this.state,
                    myNotifications,
                    myNotificationsLoaded: true
                })
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }
    
    render() {
        return (
            <Consumer>
                    {value => {
                        const { isLoggedIn, userID, dispatch } = value;
    
        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

        if (!persistedState || !persistedState.userID || persistedState.userID === '') {
            this.props.history.push('/');
        } else {
            if (userID === persistedState.userID) {
                if (!this.state.subsLoaded) {    
                    this.getSubs(userID);
                } else {
                    if (!this.state.notificationsLoaded) {
                        this.getNotifications(this.state.subs);
                    }
                }
            }
            
            if (this.state.profileUserID !=='') {
                if (!this.state.dataLoaded) {
                    this.getUserData(this.state.profileUserID, persistedState);
                }
            
                if (this.state.fullName !== '' && !this.state.robsLoaded) {
                    this.getUserRobs(this.state.profileUserID);
                }

                if (this.state.robs.length > 0 && !this.state.currentRobsLoaded) {
                    this.paginate();
                }

                if (persistedState.userID !== '') {
                    this.getCurrentUserProfilePicture(persistedState.userID)
                }

                if (!this.state.myNotificationsLoaded) {
                    this.getMyNotifications(this.state.profileUserID);
                }
            }
        }

        return (
            <div className="App">
                <div id="sidebar-bg">
			
            <header id="videohead-pro" class="sticky-header">
                <div id="video-logo-background"><Link to="/dashboard"><img src="/assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                  
                  <div id="video-search-header">
                      <div id="search-icon-more"></div>
                      <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                  </div>
                  
                  <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                  
                  
                  <div id="header-user-profile">
                      <div id="header-user-profile-click" class="noselect">
                          <img src={this.state.currentUserProfilePictureFileName} />
                            <div id="header-username">{this.state.currentUserFullName}</div><i class="fas fa-angle-down"></i>
                      </div>
                      <div id="header-user-profile-menu">
                          <ul>
                              <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                              <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                              <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                              <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                              <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                          </ul>
                      </div>
                  </div>
                  
                  <div id="header-user-notification">
                      <div id="header-user-notification-click" class="noselect">
                          <i class="far fa-bell"></i>
                          <span class="user-notification-count">3</span>
                      </div>
                      <div id="header-user-notification-menu">
                          <h3>Notifications</h3>
                          <div id="header-notification-menu-padding">
                                  <ul id="header-user-notification-list">
                                      { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                  </ul>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                  </div>

                  <div id="header-user-create">
                        <div id="header-user-create-click" class="noselect">
                            <NavLink to="/dashboard-video-upload">
                                <img src="/assets/images/create.png" alt="Create"/>
                            </NavLink>
                        </div>
                    </div>
                  
                  
                  
                  <div class="clearfix"></div>
                  
                  <nav id="mobile-navigation-pro">
                  
                  <ul id="mobile-menu-pro">
                  <li>
                            <NavLink to="/dashboard">
                                <span class="icon-Fire-Flame"></span>
                            Popular
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                Subscriptions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
                        <li>
                            <a href="#!">
                                <i class="far fa-bell"></i>
                                <span class="user-notification-count">3</span>
                            Notifications
                            </a>
                        </li>
                        </ul>
                      <div class="clearfix"></div>
                      
                      <div id="search-mobile-nav-pro">
                          <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                      </div>
                      
                  </nav>
                  
            </header>
              
              
              
              <nav id="sidebar-nav">
                  <ul id="vertical-sidebar-nav" class="sf-menu">
                  <li class="normal-item-pro">  
                        <NavLink to="/dashboard">
                              <span class="icon-Fire-Flame"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
      
                  </ul>
                      <div class="clearfix"></div>
              </nav>
              
              
              <div id="content-sidebar-pro">
                  
                  <div id="content-sidebar-info">
                      <img src={this.state.profilePictureFileName}/>
                      <div id="profile-sidebar-name">
                        <h5>{this.state.fullName}</h5>
                      </div>
                      <div id="profile-sidebar-gradient"></div>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h3 class="content-sidebar-sub-header">Robs</h3>
                      <ul id="profile-watched-stats">
                            <li><span>{this.state.robs.length}</span> Videos</li>
                      </ul>
                  </div>
                  

                  
                  <div class="content-sidebar-section">
                      <h3 class="content-sidebar-sub-header">User Activity</h3>
                      <ul id="friends-activity-feed">
                      { this.state.myNotifications.map((notification) => {
                                return(
                                    <li key={notification.key}><img src="/assets/graphics/notification-flat.png"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></li>
                                )
                            })
                        }
                      </ul>
                      <div class="clearfix"></div>
                  </div>
                  
                  
              </div>
          
              <main id="col-main-with-sidebar">
                  
                  <div class="dashboard-container">
                      
                      <ul class="dashboard-sub-menu">
                          <li class="current"><NavLink to="/dashboard-profile">Content</NavLink></li>

                      </ul>
                      
                      
                      <div class="row">
                      { this.state.currentRobs.map((rob) => {
                              console.log("Inside complex loop.")
                              return(
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4" key={rob.videoURL}>
                              <div class="item-listing-container-skrn">
                              <NavLink to={{ pathname: '/dashboard-movie-profile/' + rob.robID, robProps: {robID: rob.robID, ownerID: this.state.profileUserID }}}><img src={ rob.screenshotURL === '' ? "https://via.placeholder.com/450x620" : rob.screenshotURL } alt="Listing"/></NavLink>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><NavLink to={{ pathname: '/dashboard-movie-profile/' + rob.robID, robProps: {robID: rob.robID, ownerID: rob.ownerID }}}>{rob.title}</NavLink></h6>
                                      </div>
                                  </div>
                              </div>
                          </div>
                              )
                          })
                    }
                                          
                     </div>
                      
                      
                      <ul class="page-numbers">
                      {this.state.pageNumbers.map(number => {
                              if (this.state.currentPage === number) {
                                return (
                                    <li key={number}>
                                        <span id={number} class="page-numbers current" onClick={this.handleClick}>
                                            {number}
                                        </span>
                                    </li>
                                )
                              } else {
                                return (
                                    <li key={number}>
                                        <a id={number} class="page-numbers" href="#!" onClick={this.handleClick}>
                                            {number}
                                        </a>
                                    </li>
                                );
                              }
                          })
                        }
                      </ul>
                      
                      
                      
                  </div>
              </main>
              
              
              </div>
            </div>
        )
            }
}
</Consumer>
)
}
} 

export default withRouter(DashboardProfile);