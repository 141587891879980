import firebase from 'firebase/app';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyB0DZxxlQRNKVjJM-qXZC-FR4pjjWz30Pk",
    authDomain: "robberbarontv.firebaseapp.com",
    databaseURL: "https://robberbarontv.firebaseio.com",
    projectId: "robberbarontv",
    storageBucket: "robberbarontv.appspot.com",
    messagingSenderId: "89372575873",
    appId: "1:89372575873:web:399aab160c7fd5a3da2580",
    measurementId: "G-FBTZZTLXRD"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;