/// The Rating class
export default class Rating {
    /// A private holder for the key
    key;
    
    /// A private holder for the creation timestamp
    creationAt;
    
    /// A private holder for the the user who created the Kascade
    raterID;

    // The user name
    rating;

    // The rob ID
    robID;

    /// A private holder for the update timestamp
    updatedAt;
    
    /// A constuctor that takes the key and a dictionary that contains
    /// kascade data
    constructor(key, creationAt, raterID, rating, robID, updatedAt) {
        this.key = key;
        this.creationAt = creationAt;
        this.raterID = raterID;
        this.rating = rating;
        this.robID = robID;
        this.updatedAt = updatedAt;
    }
}