import firebase from '../../firebase';

export default class SearchService {

    searchByName(searchText) {
        return firebase
            .firestore()
            .collection('robSearch')
            .where('searchKey', "==", searchText.charAt(0).toUpperCase())
            .get();
    }
}