import firebase from '../../firebase';

export default class SearchServiceForUsers {

    searchByName(searchText) {
        return firebase
            .firestore()
            .collection('users')
            .where('searchKey', "==", searchText.charAt(0).toUpperCase())
            .get();
    }
}