import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import firebase from '../../firebase';
import ReactPlayer from "react-player";
import Comment from '../classes/Comment';
import Rob from '../classes/Rob';
import Notification from '../classes/Notification';
import $ from 'jquery';
import Rating from '../classes/Rating';
require('jquery-ui-bundle');
require('firebase/auth');

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = false;
  document.body.appendChild(script);
}

class DashboardMovieProfile extends React.Component  {
    state = {
        userID: '',
        fullName: '',
        dataLoaded: false,
        robID: '',
        robDate: '',
        ownerID: '',
        robURL: '',
        title: '',
        description: '',
        screenshotURL: '',
        commenterName: '',
        comment: '',
        comments: [],
        ownerFullName: '',
        robs: [],
        searchText: '',
        profilePictureFileName: '',
        profilePictureFileNameLoaded: false,
        subs: [],
        subDocIDs: [],
        currentSubDocID: '',
        subbed: false,
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false,
        plan: "",
        currentUserRating: 0,
        currentRating: 0,
        innerFunctionCalled: false,
        getCommentsCalled: false,
        getMoreRobsCalled: false,
        getUserDataCalled: false,
        getRatingsCalled: false,
        isStripeSubscriptionActive: false
    }

    componentDidMount () {
        appendScript("/assets/js/libs/jquery-3.3.1.min.js");
        appendScript("/assets/js/libs/popper.min.js");
        appendScript("/assets/js/libs/bootstrap.min.js");
        appendScript("/assets/js/navigation.js");
        appendScript("/assets/js/jquery.flexslider-min.js");
        appendScript("/assets/js/jquery-asRange.min.js");
        appendScript("/assets/js/circle-progress.min.js");
        appendScript("/assets/js/afterglow.min.js");
        appendScript("/assets/js/script.js");
        appendScript("/assets/js/script-dashboard.js");

        if ((this.props.location.robProps)) {
            this.updateViews(this.props.location.robProps.robID);

            this.setState({
                ...this.state,
                robID: this.props.location.robProps.robID,
                ownerID: this.props.location.robProps.ownerID
            });
        } else if (this.props.match.params.robID) {
            var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

            this.setState({
                ...this.state,
                robID: this.props.match.params.robID,
                ownerID: persistedState.ownerID
            })
        } else {
            var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

            this.setState({
                ...this.state,
                robID: persistedState.robID,
                ownerID: persistedState.ownerID
            })
        }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.robID !== this.props.match.params.robID) {
        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

        this.setState({
            ...this.state,
            robID: this.props.match.params.robID,
            ownerID: persistedState.ownerID,
            dataLoaded: false
        })
    }
  }

  logOut = (e) => {
    firebase.auth().signOut().then(function() {
        // Sign-out successful.
        var loggedOutState = {
            isLoggedIn: false,
            userID: '',
            fullName: '',
        };

        localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
    }).catch(function(error) {
        // An error happened.
    });
}

    handleClick = (e) => {
        e.preventDefault();
        if (e.target.id == "subscribeButton") {
            if (this.state.userID !== '' && this.state.ownerID !=='') {
                if (!this.state.subbed) {
                    if (this.state.userID !== this.state.ownerID) {
                        this.subscribe(this.state.userID, this.state.ownerID);
                        
                        $('#subscribeButton').text('Unsubscribe');
                        $('#subscribeButton').prop('disabled', true);
                    }
                } else {
                    this.unsubscribe(this.state.userID, this.state.currentSubDocID, this.state.ownerID);
                }
            }
        } else {
            if (e.target.name == "rating-pro") {
                console.log('Rating star clicked: ', e.target.value);
                const currentUserRating = parseInt(e.target.value, 10);
                this.setRatingData(this.state.robID, this.state.userID, currentUserRating);
            } else {
                console.log('Save comment clicked!');
                this.setCommentData(this.state.robID, this.state.userID, this.state.comment, this.state.commenterName);
            }
        }
    }

    setCommentData = (robID, userID, comment, commenterName) => {
        const commentRef = firebase.firestore().collection('comments').doc(robID).collection('data')

        commentRef
            .add({
                userID,
                comment,
                robID,
                commenterName,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                console.log('Comment added.')

                this.setState({
                    ...this.state,
                    comment: '',
                    commenterName: '',
                });
            })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        if (e.target.id === 'searchText') {            
            this.props.history.push('/dashboard-search/' + e.target.value);
        }
    }

    getComments = (robID) => {
        if (!this.state.getCommentsCalled) {
            var commentsRef = firebase.firestore().collection('comments').doc(robID).collection('data');

            commentsRef.get()
                .then((snapshot) => {
                    var comments = [];

                    snapshot.forEach((doc) => {
                        var commentID = doc.id;
                        const { comment, commenterName, createdAt, updateAt, robID, userID } = doc.data();
                        var commentObject = new Comment(commentID, robID, userID, comment, commenterName, createdAt, updateAt)
                        comments.push(commentObject);
                    });

                    this.setState({
                        ...this.state,
                        comments,
                        getCommentsCalled: true
                    });

                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });

            if (this.state.ownerID !== '') {
                this.getUserData(this.state.ownerID);
            }

            if (this.state.userID !== '') {
                this.getCurrentUserProfilePicture(this.state.userID);
                this.getStripeSubscriptionStatus(this.state.userID);
            }
        }
    }

    getUserData = (userID) => {
        if (!this.state.getUserDataCalled) {
            var userRef = firebase.firestore().collection('users').doc(userID)

            userRef.get().then((user) => {
                if (user.exists) {
                    const { fullName, profilePictureFileName } = user.data()

                    if (!this.state.dataLoaded) {
                        this.setState({
                            ...this.state,
                            ownerFullName: fullName,
                            getUserDataCalled: true
                        });

                        this.getMoreRobs(userID);
                    }
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    }

    getCurrentUserProfilePicture = (userID) => {
        if (!this.state.profilePictureFileNameLoaded) {
            var userRef = firebase.firestore().collection('users').doc(userID)

            userRef.get().then((user) => {
                if (user.exists) {
                    const { profilePictureFileName, plan } = user.data();

                    if (!this.state.profilePictureFileNameLoaded) {
                        this.setState({
                            ...this.state,
                            profilePictureFileNameLoaded: true,
                            profilePictureFileName
                        });
                    }
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    }

    getMoreRobs = (userID) => {
        if (!this.state.getMoreRobsCalled) {
            console.log('Inside getMoreRobs = (userID) 1');
            var robRef = firebase.firestore().collection('robs').doc(userID).collection('data');

            robRef.get()
                .then((snapshot) => {
                    var robs = [];

                    snapshot.forEach((doc) => {
                        if ((doc.data().robID !== this.state.robID) && (robs.length < 3)) {
                            const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views } = doc.data();
                            var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views)
                            robs.push(robObject);

                            robs.sort(this.compare)
                        }
                    });

                    this.setState({
                        ...this.state,
                        getMoreRobsCalled: true,
                        robs
                    })

                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
        }
    }

    compare = (a, b) => {
        if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
        if (b.createdAt.toDate() < a.createdAt.toDate()) return -1;
      
        return 0;
    }

    subscribe = (subscriber, creator) => {
        const subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef
            .add({
                subscriber,
                creator,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                this.updateUserSubscriberCount(creator, 1);

                var subs = [];
                subs.push(creator);

                this.setState({
                    ...this.state,
                    subs
                });
            }).catch(function(error) {
                console.log("Error getting document:", error);

                $('#subscribeButton').text('Subscribe');
                $('#subscribeButton').prop('disabled', false);
            });
    }

    getSubs = (subscriber) => {
        if (!this.state.subsLoaded) {
            var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data');

            subRef.get()
                .then((snapshot) => {
                    var subs = [];
                    var subDocIDs = [];
                    var currentSubDocID = '';

                    snapshot.forEach((doc) => {
                        const { creator } = doc.data();
                        subs.push(creator);
                        subDocIDs.push(doc.id);
                    });

                    var subbed = false;
                    var subsLoaded = true;

                    if ($.inArray(this.state.ownerID, subs) !== -1) {
                        subbed = true;
                        const index = subs.indexOf(this.state.ownerID);

                        currentSubDocID = subDocIDs[index];
                    }

                    this.setState({
                        ...this.state,
                        subs,
                        subDocIDs,
                        currentSubDocID,
                        subbed,
                        subsLoaded
                    })

                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
        }
    }

    unsubscribe = (subscriber, currentSubDocID, creator) => {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data').doc(currentSubDocID);

        subRef.delete()
            .then(() => {
                console.log('Subscription successfully removed.')

                this.updateUserSubscriberCount(creator, -1);

                $('#subscribeButton').text('Subscribe');
                $('#subscribeButton').prop('disabled', false);
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    updateViews = (robID) => {
        var robMetaReft = firebase.firestore().collection('robMeta').doc(robID);

        firebase.firestore().runTransaction((transaction) => {
            return transaction.get(robMetaReft).then((snapshot) => {
                var views = snapshot.data().views + 1;
                
                transaction.set(robMetaReft, {
                    ...snapshot.data(),
                    views,
                });

                return views;
            });
        }).then((age) => {
            console.log("Age changed to ", age);
        }).catch(function (err) {
            console.error(err);
        });
    }

    updateUserSubscriberCount = (creator, incrementSize) => {
        let userRef = firebase.firestore().collection('users').doc(creator);

        firebase.firestore().runTransaction((transaction) => {
            return transaction.get(userRef).then((snapshot) => {
                var subscriberCount;
                if (snapshot.data().subscriberCount) {
                    subscriberCount = snapshot.data().subscriberCount + incrementSize;
                } else {
                    subscriberCount = incrementSize;
                }
                
                transaction.set(userRef, {
                    ...snapshot.data(),
                    subscriberCount,
                });

                return subscriberCount;
            });
        }).then((age) => {
            console.log("Done.");
        }).catch(function (err) {
            console.error(err);
        });
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }

    getNotifications = (subs) => {
        if (!this.state.notificationsLoaded) {
            var count = 0;

            subs.forEach((creator) => {
                var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
                
                notificationRef.get()
                    .then((snapshot) => {
                        var notifications = [];

                        snapshot.forEach((doc) => {
                            const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                            var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                            
                            notifications.push(notificationObject);

                            notifications.sort(this.compareNotifications)
                        });

                        count++;

                        if (count === subs.length) {
                            this.setState({
                                ...this.state,
                                notifications,
                                notificationsLoaded: true
                            })
                        }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            });
        }
    }

    setRatingData = (robID, raterID, rating) => {
        const ratingRef = firebase.firestore().collection('ratings').doc(robID).collection('data').doc(raterID)

        ratingRef
            .set({
                raterID,
                robID,
                rating,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                console.log('Rating set.')

                this.setState({
                    ...this.state,
                    currentUserRating: rating
                });
            })
    }

    getRatings = (robID) => {
        if (!this.state.getRatingsCalled) {
            const ratingRef = firebase.firestore().collection('ratings').doc(robID).collection('data');

            ratingRef.get()
                .then((snapshot) => {
                    var ratings = [];

                    snapshot.forEach((doc) => {
                        var ratingID = doc.id;
                        const { createdAt, raterID, rating, robID, updateAt} = doc.data();
                        var ratingObject = new Rating(ratingID, createdAt, raterID, rating, robID, updateAt)
                        ratings.push(ratingObject);
                    });

                    var currentRating = 0;
                    var ratingSum = 0;

                    var i;
                    for (i = 0; i < ratings.length; i++) {
                        ratingSum += ratings[i].rating;
                    }

                    if (ratings.length > 0) {
                        currentRating = ratingSum / ratings.length;
                    }

                    this.setState({
                        ...this.state,
                        currentRating,
                        getRatingsCalled: true
                    });

                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
        }
    }

    getStripeSubscriptionStatus = (userID) => {
        let subscriptionsRef = firebase.firestore().collection('users').doc(userID).collection('subscriptions');
            
        subscriptionsRef.get()
            .then((snapshot) => {
                var notifications = [];

                snapshot.forEach((doc) => {
                    let { status, current_period_end } = doc.data();

                    if (status === 'active') {
                        this.setState({
                            ...this,
                            isStripeSubscriptionActive: true
                        });
                    }
                });
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    render() {
        var playbackContent;
        var subscribeButton = '';

        if (this.state.userID === this.state.ownerID) {
            playbackContent = <ReactPlayer width='820px' height='560px' controls={ true } url={this.state.robURL} />
        } else {
            subscribeButton = <a href="#!"  class="btn btn-green-pro btn-sm" id="subscribeButton" onClick={this.handleClick}>{ this.state.subbed ? "Unsubscribe" : "Subscribe" }</a>;
            if (!this.state.isStripeSubscriptionActive) {
                playbackContent = <div class="progression-studios-slider-dashboard-image-background" style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/robberbarontv.appspot.com/o/playback-disabled-smaller.png?alt=media&token=6b4208e2-49b5-4c75-82dd-d44f62c8100c)' }}></div>;
            } else {
                playbackContent = <ReactPlayer width='820px' height='560px' controls={ true } url={this.state.robURL} />
            }
        }

        return (
            <Consumer>
                    {value => {
                        const { isLoggedIn, userID, dispatch } = value;
                        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                        if (!persistedState || !persistedState.userID || persistedState.userID === '') {
                            this.props.history.push('/');
                        } else {
                            if (userID === persistedState.userID) {
                                if (!this.state.subsLoaded) {    
                                    this.getSubs(userID);
                                } else {
                                    if (!this.state.notificationsLoaded) {
                                        this.getNotifications(this.state.subs);
                                    }
                                }

                                if (this.state.robID !== '' && this.state.ownerID !== '') {
                                    if (!this.state.innerFunctionCalled) {
                                        var robRef = firebase.firestore().collection('robs').doc(this.state.ownerID).collection('data').doc(this.state.robID)

                                        robRef.get().then((rob) => {
                                            if (rob.exists) {
                                                const { description, createdAt, genre, screenshotURL, tags, title, videoURL } = rob.data()

                                                if (!this.state.dataLoaded) {
                                                    dispatch({
                                                        type: 'USER_STATE',
                                                        payload: {
                                                            isLoggedIn: true,
                                                            userID,
                                                            fullName: persistedState.fullName,
                                                            ownerID: this.state.ownerID,
                                                            robID: this.state.robID
                                                        }
                                                    });

                                                    this.setState({
                                                        ...this.state,
                                                        userID,
                                                        fullName: persistedState.fullName,
                                                        robURL: videoURL,
                                                        title,
                                                        description,
                                                        screenshotURL,
                                                        robDate: createdAt.toDate().toString()
                                                    });

                                                    this.getComments(this.state.robID);
                                                    this.getRatings(this.state.robID);

                                                    this.setState({
                                                        ...this.state,
                                                        innerFunctionCalled: true
                                                    });
                                                } 
                                            } else {
                                                console.log("No such document!");
                                            }
                                        }).catch(function(error) {
                                            console.log("Error getting document:", error);
                                        });
                                    }
                                }
                            }
                        }
        return (
            <div className="App">
                <div id="sidebar-bg">
			
            <header id="videohead-pro" class="sticky-header">
                <div id="video-logo-background"><Link to="/dashboard"><img src="/assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                  
                  <div id="video-search-header">
                      <div id="search-icon-more"></div>
                      <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                  </div>
                  
                  <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                  
                  
                  <div id="header-user-profile">
                      <div id="header-user-profile-click" class="noselect">
                          <img src={this.state.profilePictureFileName} />
                            <div id="header-username">{this.state.fullName}</div><i class="fas fa-angle-down"></i>
                      </div>
                      <div id="header-user-profile-menu">
                          <ul>
                              <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                              <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                              <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                              <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                              <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                          </ul>
                      </div>
                  </div>
                  
                  

                  <div id="header-user-create">
                        <div id="header-user-create-click" class="noselect">
                            <NavLink to="/dashboard-video-upload">
                                <img src="/assets/images/create.png" alt="Create"/>
                            </NavLink>
                        </div>
                    </div>
                  
                  
                  
                  <div class="clearfix"></div>
                  
                  <nav id="mobile-navigation-pro">
                  
                  <ul id="mobile-menu-pro">
                  <li>
                            <NavLink to="/dashboard">
                                <span class="icon-Fire-Flame"></span>
                            Popular
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                Subscriptions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
                        <li>
                            <a href="#!">
                                <i class="far fa-bell"></i>
                                <span class="user-notification-count">3</span>
                            Notifications
                            </a>
                        </li>
                        </ul>
                      <div class="clearfix"></div>
                      
                      <div id="search-mobile-nav-pro">
                          <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                      </div>
                      
                  </nav>
                  
            </header>
              
              
              
              <nav id="sidebar-nav">
                  <ul id="vertical-sidebar-nav" class="sf-menu">
                  <li class="normal-item-pro">  
                        <NavLink to="/dashboard">
                              <span class="icon-Fire-Flame"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
      
                  </ul>
                      <div class="clearfix"></div>
              </nav>
              
              
              <div id="content-sidebar-pro">
                  
                  <div id="content-sidebar-image">
                      <img src={ this.state.screenshotURL === '' ? "https://via.placeholder.com/450x620" : this.state.screenshotURL } alt="Movie Poster"/>
                  </div>
                  
                  <div class="content-sidebar-section">
                        <h2 class="content-sidebar-sub-header">{this.state.title}</h2>
                      <ul class="progression-studios-slider-rating">
                          <li>PG-13</li><li>HD</li>
                      </ul>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h4 class="content-sidebar-sub-header">Release Date</h4>
                      <div class="content-sidebar-short-description">{this.state.robDate}</div>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h4 class="content-sidebar-sub-header">Creator</h4>
                      <div class="content-sidebar-short-description"><NavLink to={"/dashboard-profile/" + this.state.ownerID}>{this.state.ownerFullName}</NavLink></div>
                      <h4 class="content-sidebar-sub-header"></h4>
                      { subscribeButton }
                  </div>
      
                  
                  
                  <div class="content-sidebar-section">
                      <h2 class="content-sidebar-sub-header adjusted-recent-reviews">Recent Comments</h2>
                      <ul id="sidebar-reviews-pro">
                            { this.state.comments.map((comment) => {
                                    return(
                                        <li key={comment.key}>
                                            <h6>{comment.commenterName}</h6>
                                            <div class="sidebar-review-time">{comment.creationAt.toDate().toString()}</div>
                                            <p>{comment.comment}</p>
                                        </li>
                                    )
                                })
                            }
                      </ul>
                  </div>
                  
              </div>
              
              
              <main id="col-main-with-sidebar">
              
                  
                  <div id="movie-detail-header-pro">
                  
                                
                      
                  { playbackContent }
                  </div>
                  
                  
                  <div id="movie-detail-rating">
                      <div class="dashboard-container">
                          <div class="row">
                              <div class="col-sm">
                                  <h5>Rate '{this.state.title}'</h5>
                                  
                                  <div class="rating-pro">
                                       <label>
                                         <input type="radio" name="rating-pro" value="10" title="10 stars" onClick={this.handleClick}/> 10
                                       </label>
                                      <label>
                                        <input type="radio" name="rating-pro" value="9" title="9 stars" onClick={this.handleClick}/> 9
                                      </label>
                                      <label>
                                        <input type="radio" name="rating-pro" value="8" title="8 stars" onClick={this.handleClick}/> 8
                                      </label>
                                      <label>
                                        <input type="radio" name="rating-pro" value="7" title="7 stars" onClick={this.handleClick}/> 7
                                      </label>
                                     <label>
                                       <input type="radio" name="rating-pro" value="6" title="6 stars" onClick={this.handleClick}/> 6
                                     </label>
                                    <label>
                                      <input type="radio" name="rating-pro" value="5" title="5 stars" onClick={this.handleClick}/> 5
                                    </label>
                                    <label>
                                      <input type="radio" name="rating-pro" value="4" title="4 stars" onClick={this.handleClick}/> 4
                                    </label>
                                    <label>
                                      <input type="radio" name="rating-pro" value="3" title="3 stars" onClick={this.handleClick}/> 3
                                    </label>
                                    <label>
                                      <input type="radio" name="rating-pro" value="2" title="2 stars" onClick={this.handleClick}/> 2
                                    </label>
                                    <label>
                                      <input type="radio" name="rating-pro" value="1" title="1 star" onClick={this.handleClick}/> 1
                                    </label>
                                  </div>
                                  
                              </div>
                              <div class="col-sm">
                                  <h6>User Rating</h6>
                                <div
                                  class="circle-rating-pro"
                                  data-value="1.0"
                                  data-animation-start-value="0"
                                  data-size="40"
                                  data-thickness="3"
                                  data-fill="{
                                    &quot;color&quot;: &quot;#42b740&quot;
                                  }"
                                  data-empty-fill="#def6de"
                                  data-reverse="true"
                                ><span style={{ color: '#ff4141' }}>{ this.state.currentRating === 0 ? '*' : this.state.currentRating }</span></div>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                      </div>
                  </div>
                  
                  <div class="dashboard-container">
                      
                      
                      <div class="movie-details-section">
                          <h2>Description</h2>
                                <p>{this.state.description}</p>
                      </div>
                          
                      <div class="movie-details-section">
                          <h2>Post a comment:</h2>
                          <form class="account-settings-form" onSubmit={this.handleSubmit}>
                                    
                                            <p class="small-paragraph-spacing">Post a comment about the content you just watched. Remember to enter your name as well.</p>
                                            
                                            <div class="row">
                                                <div class="col-sm">
                                                        <div class="form-group">
                                                        <label for="e-mail" class="col-form-label">Your name:</label>
                                                            <input type="text" class="form-control" id="commenterName" onChange={this.handleChange} value={this.state.commenterName}/>
                                                            <br />
                                                            <label for="exampleFormControlTextarea1">Your comment:</label>
                                                            <textarea class="form-control" id="comment" rows="10" onChange={this.handleChange} value={this.state.comment}></textarea>
                                                        </div>
                                                </div>
                                                <div class="col-sm">
                                                        <div class="form-group">
                                                            <div><label for="button-change" class="col-form-label">&nbsp; &nbsp;</label></div>
                                                            <a href="#!" class="btn btn-form" onClick={this.handleClick} type="submit"><i class="fas fa-save"></i>Post Comment</a>
                                                        </div>
                                                </div>
                                                
                                            </div>
                                            </form>
                      
                      </div>
                      
                  </div>
              </main>
              
              
              </div>

            </div>
        )
    }
}
</Consumer>
)
}
} 

export default withRouter(DashboardMovieProfile);