import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import firebase from '../../firebase';
import Spinner2 from '../layout/Spinner';
import Notification from '../classes/Notification';
import Spinner from 'react-spinner-material';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

export const loadScripts = () => {
    appendScript("assets/js/libs/jquery-3.3.1.min.js");
    appendScript("assets/js/libs/popper.min.js");
    appendScript("assets/js/libs/bootstrap.min.js");
    appendScript("assets/js/navigation.js");
    appendScript("assets/js/jquery.flexslider-min.js");
    appendScript("assets/js/jquery-asRange.min.js");
    appendScript("assets/js/circle-progress.min.js");
    appendScript("assets/js/afterglow.min.js");
    appendScript("assets/js/script.js");
    appendScript("assets/js/script-dashboard.js");
}

class DashboardAccount extends React.Component  {
    state = {
        userID: '',
        isLoggedIn: false,
        firstName: '',
        lastName: '',
        email: '',
        fullName: '',
        dataLoaded: false,
        profilePictureFileName: '',
        searchText: '',
        subs: [],
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false,
        isUpdatingScreenshot: false
    }

    componentDidMount () {
        loadScripts();
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: ''
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    handleChange = (e) => {
        $('#saveUserText').css('color', '#000000');
        $('#saveUserText').text('Click "Save Changes" to update your user data.');

        if (e.target.id === "profilePictureFileName") {
            this.setState({
                ...this.state,
                isUpdatingScreenshot: true
            })

            var storageRef = firebase.storage().ref(this.generateUUID())
            var screenshotFile = e.target.files[0] // upload the first file only
            
            storageRef
                .put(screenshotFile)
                .then(snapshot => {
                    snapshot.ref.getDownloadURL().then(url => {
                        console.log(' * new url', url)
                        this.setState({
                            ...this.state,
                            profilePictureFileName: url,
                            isUpdatingScreenshot: false
                        })
                    })
                })
        } else {
            this.setState({
                [e.target.id]: e.target.value
            })

            if (e.target.id === 'searchText') {            
                this.props.history.push('/dashboard-search/' + e.target.value);
            }
        }
    }

    updateUserData = (firstName, lastName, fullName, email, profilePictureFileName, userID) => {
        const userRef = firebase.firestore().collection('users').doc(userID)

        userRef
            .update({
                firstName,
                lastName,
                fullName,
                email,
                profilePictureFileName,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('Update compvare.')
            })
    }

    handleClick = (e) => {
        e.preventDefault();

        const localFullName = this.state.firstName + ' ' + this.state.lastName;

        this.setState({
            ...this.state,
            fullName: localFullName
        })

        if (this.state.firstName === '') {
            $('#saveUserText').css('color', '#FF0000');
            $('#saveUserText').text('Please enter your first name.');
        } else if (this.state.profilePictureFileName === '') {
            $('#saveUserText').css('color', '#FF0000');
            $('#saveUserText').text('Click the "Choose File" button to choose your picture.');
        } else if (this.state.lastName === '') {
            $('#saveUserText').css('color', '#FF0000');
            $('#saveUserText').text('Please enter your last name.');
        } else if (this.state.email === '') {
            $('#saveUserText').css('color', '#FF0000');
            $('#saveUserText').text('Please enter your email.');
        } else {
            this.updateUserData(this.state.firstName, this.state.lastName, localFullName, this.state.email, this.state.profilePictureFileName, this.state.userID);
        }
    }

    generateUUID = () => { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depvared
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                })

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }

    render() {
        const isUpdatingScreenshot = this.state.isUpdatingScreenshot;
        var pictureSection;
        if (isUpdatingScreenshot) {
            pictureSection = <div><Spinner size={120} spinnerColor={"#333"} spinnerWidth={4} visible={true} /></div>;
        } else {
            pictureSection = <div><img src={this.state.profilePictureFileName == '' ? "https://via.placeholder.com/400x400.jpg" : this.state.profilePictureFileName } alt="Account Image"/></div>;
        }

        return (
            <Consumer>
                {value => {
                    const { isLoggedIn, userID, dispatch } = value;

                    var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                    if (!persistedState || !persistedState.userID || persistedState.userID === '') {
                        this.props.history.push('/');
                    } else {

                    var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                    if (userID === persistedState.userID) {
                        if (!this.state.subsLoaded) {    
                            this.getSubs(userID);
                        } else {
                            if (!this.state.notificationsLoaded) {
                                this.getNotifications(this.state.subs);
                            }
                        }
                    }

                    if ((userID !== persistedState.userID) || (userID === '')) {
                        return (
                            <div>
                                <div id="sidebar-bg">
                                    <header id="videohead-pro" class="sticky-header">
                                        <div id="video-logo-background"><Link to="/dashboard"><img src="assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                                            
                                            <div id="video-search-header">
                                                <div id="search-icon-more"></div>
                                                <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                                            </div>
                                            
                                            <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                                            
                                            
                                            <div id="header-user-profile">
                                                <div id="header-user-profile-click" class="noselect">
                                                    <img src="assets/images/demo/user-profile.jpg" />
                                                    <div id="header-username"></div><i class="fas fa-angle-down"></i>
                                                </div>
                                                <div id="header-user-profile-menu">
                                                    <ul>
                                                        <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                                                        <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                                                        <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                                                        <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                                                        <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                            <div id="header-user-notification">
                                                <div id="header-user-notification-click" class="noselect">
                                                    <i class="far fa-bell"></i>
                                                    <span class="user-notification-count">3</span>
                                                </div>
                                                <div id="header-user-notification-menu">
                                                    <h3>Notifications</h3>
                                                    <div id="header-notification-menu-padding">
                                                            <ul id="header-user-notification-list">
                                                                { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                                            </ul>
                                                            <div class="clearfix"></div>
                                                        </div>
                                                    </div>
                                            </div>

                                            <div id="header-user-create">
                                                    <div id="header-user-create-click" class="noselect">
                                                        <NavLink to="/dashboard-video-upload">
                                                            <img src="assets/images/create.png" alt="Create"/>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            
                                            
                                            
                                            <div class="clearfix"></div>
                                            
                                            <nav id="mobile-navigation-pro">
                                            
                                            <ul id="mobile-menu-pro">
                                                <li>
                                                    <NavLink to="/dashboard">
                                                        <span class="icon-Fire-Flame"></span>
                                                    Popular
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/dashboard-subs">
                                                        <span class="icon-RSS"></span>
                                                        New Releases
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/dashboard-new-releases">
                                                        <span class="icon-Paper-Plane"></span>
                                                        Subscriptions
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/dashboard-mine">
                                                        <span class="icon-Profile"></span>
                                                        Mine
                                                    </NavLink>
                                                </li>
                                                    <li>
                                                        <a href="#!">
                                                            <i class="far fa-bell"></i>
                                                            <span class="user-notification-count">3</span>
                                                        Notifications
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div class="clearfix"></div>
                                                
                                                <div id="search-mobile-nav-pro">
                                                    <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                                                </div>
                                                
                                            </nav>
                                            
                                        </header>
                                        
                                        
                                        
                                        <nav id="sidebar-nav">
                                            <ul id="vertical-sidebar-nav" class="sf-menu">
                                            <li class="normal-item-pro">  
                                            <NavLink to="/dashboard">
                                                <span class="icon-Fire-Flame"></span>
                                            Popular
                                            </NavLink>
                                            </li>
                                            <li class="normal-item-pro">
                                                <NavLink to="/dashboard-subs">
                                                    <span class="icon-RSS"></span>
                                                    Subs
                                                </NavLink>
                                            </li>
                                            <li class="normal-item-pro">
                                                <NavLink to="/dashboard-new-releases">
                                                    <span class="icon-Paper-Plane"></span>
                                                    New Releases
                                                </NavLink>
                                            </li>
                                            <li class="normal-item-pro">
                                            <NavLink to="/dashboard-mine">
                                                    <span class="icon-Profile"></span>
                                                    Mine
                                                </NavLink>
                                            </li>
                                
                                            </ul>
                                                <div class="clearfix"></div>
                                        </nav>
                                    <Spinner2 />
                                </div>
                            </div>
                        )
                    } else {
                        var userRef = firebase.firestore().collection('users').doc(userID)

                        userRef.get().then((user) => {
                            if (user.exists) {
                                const { email, firstName, lastName, fullName, profilePictureFileName } = user.data()

                                if (!this.state.dataLoaded) {
                                    this.setState({
                                        ...this.state,
                                        dataLoaded: true,
                                        email,
                                        firstName,
                                        lastName,
                                        fullName,
                                        userID,
                                        profilePictureFileName
                                    });
                                }
                            } else {
                                console.log("No such document!");
                            }
                        }).catch(function(error) {
                            console.log("Error getting document:", error);
                        });

                        return (
                            <div className="App">
                                <div id="sidebar-bg">
                                    <header id="videohead-pro" class="sticky-header">
                                    <div id="video-logo-background"><Link to="/dashboard"><img src="assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                                        
                                        <div id="video-search-header">
                                            <div id="search-icon-more"></div>
                                            <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                                        </div>
                                        
                                        <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                                        
                                        
                                        <div id="header-user-profile">
                                            <div id="header-user-profile-click" class="noselect">
                                                <img src={this.state.profilePictureFileName} />
                                                <div id="header-username">{this.state.fullName}</div><i class="fas fa-angle-down"></i>
                                            </div>
                                            <div id="header-user-profile-menu">
                                                <ul>
                                                    <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                                                    <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                                                    <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                                                    <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                                                    <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div id="header-user-notification">
                                            <div id="header-user-notification-click" class="noselect">
                                                <i class="far fa-bell"></i>
                                                <span class="user-notification-count">3</span>
                                            </div>
                                            <div id="header-user-notification-menu">
                                                <h3>Notifications</h3>
                                                <div id="header-notification-menu-padding">
                                                        <ul id="header-user-notification-list">
                                                            { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                                        </ul>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                        </div>

                                        <div id="header-user-create">
                                                <div id="header-user-create-click" class="noselect">
                                                    <NavLink to="/dashboard-video-upload">
                                                        <img src="assets/images/create.png" alt="Create"/>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        
                                        
                                        
                                        <div class="clearfix"></div>
                                        
                                        <nav id="mobile-navigation-pro">
                                        
                                        <ul id="mobile-menu-pro">
                                        <li>
                                            <NavLink to="/dashboard">
                                                <span class="icon-Fire-Flame"></span>
                                            Popular
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard-subs">
                                                <span class="icon-RSS"></span>
                                                New Releases
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard-new-releases">
                                                <span class="icon-Paper-Plane"></span>
                                                Subscriptions
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dashboard-mine">
                                                <span class="icon-Profile"></span>
                                                Mine
                                            </NavLink>
                                        </li>
                                                <li>
                                                    <a href="#!">
                                                        <i class="far fa-bell"></i>
                                                        <span class="user-notification-count">3</span>
                                                    Notifications
                                                    </a>
                                                </li>
                                                </ul>
                                            <div class="clearfix"></div>
                                            
                                            <div id="search-mobile-nav-pro">
                                                <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                                            </div>
                                        </nav>
                                    </header>
                                    <nav id="sidebar-nav">
                                        <ul id="vertical-sidebar-nav" class="sf-menu">
                                        <li class="normal-item-pro">  
                        <NavLink to="/dashboard">
                              <span class="icon-Fire-Flame"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
                            
                                        </ul>
                                            <div class="clearfix"></div>
                                    </nav>
                                
                                    <main id="col-main">
                                        
                                        <div class="dashboard-container">
                                            
                                            <ul class="dashboard-sub-menu">
                                                <li class="current"><NavLink to="/dashboard-account">Account Settings</NavLink></li>
                                                <li><NavLink to="/dashboard-account-payment">Payment Info</NavLink></li>
                                            </ul>
                                            
                                            <div class="container-fluid">
                                                <div class="row">
                                                
                                                    <div class="col-12  col-lg-3">
                                                        <div id="account-edit-photo">
                                                            {pictureSection}
                                                            <p><input type="file" id="profilePictureFileName" accept="image/*" onChange={this.handleChange} style={{ width: '200px'}}/></p>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                    
                                                        <form class="account-settings-form">
                                                    
                                                            <h5>General Information</h5>
                                                            <p class="small-paragraph-spacing">By varting us know your name, we can make our support experience much more personal.</p>
                                                            <div class="row">
                                                                <div class="col-sm">
                                                                        <div class="form-group">
                                                                        <label for="first-name" class="col-form-label">First Name:</label>
                                                                            <input type="text" class="form-control" id="firstName" placeholder="First name" value={this.state.firstName} onChange={this.handleChange} />
                                                                        </div>
                                                                </div>
                                                                <div class="col-sm">
                                                                        <div class="form-group">
                                                                            <label for="last-name" class="col-form-label">Last Name:</label>
                                                                            <input type="text" class="form-control" id="lastName" placeholder="Last name" value={this.state.lastName} onChange={this.handleChange} />
                                                                        </div>
                                                                </div>
                                                                <div class="col-sm">
                                                                        <div class="form-group">
                                                                            <label for="last-name" class="col-form-label">Country:</label>
                                                                        <select class="custom-select">
                                                                            <option value="0">All Countries</option>
                                                                            <option value="1">Argentina</option>
                                                                            <option value="2">Australia</option>
                                                                            <option value="3">Bahamas</option>
                                                                            <option value="4">Belgium</option>
                                                                            <option value="5">Brazil</option>
                                                                            <option value="6">Canada</option>
                                                                            <option value="7">Chile</option>
                                                                            <option value="8">China</option>
                                                                            <option value="9">Denmark</option>
                                                                            <option value="10">Ecuador</option>
                                                                            <option value="11">France</option>
                                                                            <option value="12">Germany</option>
                                                                            <option value="13">Greece</option>
                                                                            <option value="14">Guatemala</option>
                                                                            <option value="15">Italy</option>
                                                                            <option value="16">Japan</option>
                                                                            <option value="17">asdfasdf</option>
                                                                            <option value="18">Korea</option>
                                                                            <option value="19">Malaysia</option>
                                                                            <option value="20">Monaco</option>
                                                                            <option value="21">Morocco</option>
                                                                            <option value="22">New Zealand</option>
                                                                            <option value="23">Panama</option>
                                                                            <option value="24">Portugal</option>
                                                                            <option value="25">Russia</option>
                                                                            <option value="26">United Kingdom</option>
                                                                            <option selected>United States</option>
                                                                        </select>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                            <h5>Account Information</h5>
                                                            <p class="small-paragraph-spacing">You can change the email address you use here.</p>
                                                            <div class="row">
                                                                <div class="col-sm">
                                                                    <div class="form-group">
                                                                        <label for="e-mail" class="col-form-label">E-mail</label>
                                                                        <input type="text" class="form-control" id="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm">
                                                                    <div class="form-group">
                                                                        <div><label for="button-change" class="col-form-label">&nbsp; &nbsp;</label></div>
                                                                        <a href="#!" class="btn btn-form">Change E-mail</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                            <h5>Change Password</h5>
                                                            <p class="small-paragraph-spacing">You can change the password you use for your account here.</p>
                                                            <div class="row">
                                                                <div class="col-sm">
                                                                        <div class="form-group">
                                                                        <label for="password" class="col-form-label">Current Password:</label>
                                                                            <input type="text" class="form-control" id="password" value="&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;"/>
                                                                        </div>
                                                                </div>
                                                                <div class="col-sm">
                                                                        <div class="form-group">
                                                                        <label for="new-password" class="col-form-label">New Password:</label>
                                                                            <input type="text" class="form-control" id="new-password" placeholder="Minimum of 6 characters"/>
                                                                        </div>
                                                                </div>
                                                                <div class="col-sm">
                                                                        <div class="form-group">
                                                                        <div><label for="confirm-password" class="col-form-label">&nbsp; &nbsp;</label></div>
                                                                            <input type="text" class="form-control" id="confirm-password" placeholder="Confirm New Password"/>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                            <div class="clearfix"></div>
                                                            <hr/>
                                                            <p><a href="#!" class="btn btn-green-pro" onClick={this.handleClick} type="submit">Save Changes</a></p>
                                                            <p id="saveUserText"class="small-paragraph-spacing">Click "Save Changes" to update your user data.</p>
                                                            <br/>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>         
                                        </div>
                                    </main>
                                </div>
                            </div>
                        )
                    }
                }
                }}
            </Consumer>
        )
    }
}

export default withRouter(DashboardAccount);