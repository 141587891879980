/// The Comment class
export default class Comment {
    /// A private holder for the key
    key;
    
    /// A private holder for the comment
    comment;
    
    /// A private holder for the creation timestamp
    creationAt;
    
    /// A private holder for the update timestamp
    updatedAt;
    
    /// A private holder for the the user who created the Kascade
    userID;

    // The user name
    commenterName;

    // The rob ID
    robID;
    
    /// A constuctor that takes the key and a dictionary that contains
    /// kascade data
    constructor(key, robID, userID, comment, commenterName, creationAt, updatedAt) {
        this.key = key;
        this.robID = robID;
        this.userID = userID;
        this.comment = comment;
        this.commenterName = commenterName;
        this.creationAt = creationAt;
        this.updatedAt = updatedAt;
    }
}
