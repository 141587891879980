import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom'
import firebase from '../../firebase';
import { Consumer } from '../../context';
import Rob from '../classes/Rob';
import SearchService from '../classes/SearchService';
import User from '../classes/User';
import Notification from '../classes/Notification';
import SearchServiceForUsers from '../classes/SearchServiceForUsers';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class DashboardSearch extends React.Component  {
    state = {
        userID: '',
        fullName: '',
        searchText: '',
        dataLoaded: false,
        queryResultSet: [],
        tempSearchStore: [],
        robs: [],
        userQueryResultSet: [],
        userTempSearchStore: [],
        users: [],
        profilePictureFileName: '',
        profilePictureFileNameLoaded: false,
        subs: [],
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false
    }

    componentDidMount () {
        appendScript("/assets/js/libs/jquery-3.3.1.min.js");
        appendScript("/assets/js/libs/popper.min.js");
        appendScript("/assets/js/libs/bootstrap.min.js");
        appendScript("/assets/js/navigation.js");
        appendScript("/assets/js/jquery.flexslider-min.js");
        appendScript("/assets/js/jquery-asRange.min.js");
        appendScript("/assets/js/circle-progress.min.js");
        appendScript("/assets/js/afterglow.min.js");
        appendScript("/assets/js/script.js");
        appendScript("/assets/js/script-dashboard.js");

        console.log('The props: ', this.props.match.params.searchText);

        this.setState({
            ...this.state,
            searchText: this.props.match.params.searchText
        });

        $("#searchText").focus();
        this.startSearch();
        this.startUserSearch();
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            console.log("Firebase signed out.")

            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: ''
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    startSearch = () => {
        var capitalizedValue = this.props.match.params.searchText.toUpperCase();

        var queryResultSet = [];
        var robs = [];

        new SearchService().searchByName(capitalizedValue)
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    queryResultSet.push(doc.data());

                    const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL } = doc.data();
                    var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL)
                    robs.push(robObject);
                });

                this.setState({
                    queryResultSet,
                    robs
                });
            })
            .catch(error => {
                console.log("Error getting documents: ", error);
            });
    }

    initiateSearch = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });

        this.initiateUserSearch();

        if (this.state.searchText.length === 0) {
            this.setState({
                queryResultSet: [],
                tempSearchStore: [],
                robs: []
            });
        }

        var capitalizedValue = this.state.searchText.charAt(0).toUpperCase() + this.state.searchText.slice(1);

        var queryResultSet = [];
        var robs = [];

        if (this.state.queryResultSet.length === 0 && this.state.searchText.length === 1) {
            new SearchService().searchByName(capitalizedValue)
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        queryResultSet.push(doc.data());

                        const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views } = doc.data();
                        var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views)
                        robs.push(robObject);
                    });

                    this.setState({
                        queryResultSet,
                        robs
                    });
                })
                .catch(error => {
                    console.log("Error getting documents: ", error);
                });
        } else {
            var tempSearchStore = [];
            var robs = [];

            this.state.queryResultSet.forEach(element => {
                if (element.title.startsWith(capitalizedValue)) {
                    tempSearchStore.push(element);

                    const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views } = element;
                    var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views)
                    robs.push(robObject);
                }
            });

            this.setState({
                tempSearchStore,
                robs
            });
        }
    }

    startUserSearch = () => {
        var capitalizedValue = this.props.match.params.searchText.toUpperCase();

        var userQueryResultSet = [];
        var users = [];

        new SearchServiceForUsers().searchByName(capitalizedValue)
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    userQueryResultSet.push(doc.data());

                    const { createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID } = doc.data();
                    var userObject = new User(userID, createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID);
                    users.push(userObject);
                });

                this.setState({
                    userQueryResultSet,
                    users
                });
            })
            .catch(error => {
                console.log("Error getting documents: ", error);
            });
    }

    initiateUserSearch = () => {
        if (this.state.searchText.length === 0) {
            this.setState({
                userQueryResultSet: [],
                userTempSearchStore: [],
                users: []
            });
        }

        var capitalizedValue = this.state.searchText.charAt(0).toUpperCase() + this.state.searchText.slice(1);

        var userQueryResultSet = [];
        var users = [];

        if (this.state.userQueryResultSet.length === 0 && this.state.searchText.length === 1) {
            new SearchServiceForUsers().searchByName(capitalizedValue)
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        userQueryResultSet.push(doc.data());

                        const { createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID } = doc.data();
                        var userObject = new User(userID, createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID);
                        users.push(userObject);
                    });

                    this.setState({
                        userQueryResultSet,
                        users
                    });
                })
                .catch(error => {
                    console.log("Error getting documents: ", error);
                });
        } else {
            var userTempSearchStore = [];
            var users = [];

            this.state.queryResultSet.forEach(element => {
                if (element.title.startsWith(capitalizedValue)) {
                    userTempSearchStore.push(element);

                    const { createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID } = element;
                    var userObject = new User(userID, createdAt, email, firstName, fullName, lastName, profilePictureFileName, searchKey, updateAt, userID);
                    users.push(userObject);
                }
            });

            this.setState({
                userTempSearchStore,
                users
            });
        }
    }

    getUserData = () => {
        if (this.state.userID) {
            var userRef = firebase.firestore().collection('users').doc(this.state.userID)

            userRef.get().then((user) => {
                if (user.exists) {
                    const { profilePictureFileName } = user.data()

                    this.setState({
                        ...this.state,
                        profilePictureFileNameLoaded: true,
                        profilePictureFileName
                    });

                    console.log('picture file name: ', profilePictureFileName)
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                })

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    console.log("The count is: ", count)

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }

    render() {
        return (
            <Consumer>
                    {value => {
                        const { isLoggedIn, userID, dispatch } = value;

                        
                        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                        if (!persistedState || !persistedState.userID || persistedState.userID === '') {
                            this.props.history.push('/');
                        } else {
                            if (!this.state.profilePictureFileNameLoaded) {
                                this.getUserData();
                            }
        
                            var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                            if (userID === persistedState.userID) {
                                if (!this.state.subsLoaded) {    
                                    this.getSubs(userID);
                                } else {
                                    if (!this.state.notificationsLoaded) {
                                        this.getNotifications(this.state.subs);
                                    }
                                }
                            }
        
                            if (persistedState.userID !== '') {
                                if (!this.state.dataLoaded) {
                                    this.setState({
                                        ...this.state,
                                        dataLoaded: true,
                                        userID: persistedState.userID,
                                        fullName: persistedState.fullName
                                    })
                                }   
                            }
                    }
    return (
        <div className="App">
            <div id="sidebar-bg">	
                <header id="videohead-pro" class="sticky-header">
                    <div id="video-logo-background"><Link to="/dashboard"><img src="/assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                    
                    <div id="video-search-header">
                        <div id="search-icon-more"></div>
                        <input type="text" placeholder="Search for a video or user's name" aria-label="Search" id="searchText" value={this.state.searchText} onChange={this.initiateSearch} />
                    </div>
                    
                    <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                    
                    
                    <div id="header-user-profile">
                        <div id="header-user-profile-click" class="noselect">
                            <img src={this.state.profilePictureFileName} />
                            <div id="header-username">{this.state.fullName}</div><i class="fas fa-angle-down"></i>
                        </div>
                        <div id="header-user-profile-menu">
                            <ul>
                                <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                                <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                                <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                                <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                                <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div id="header-user-notification">
                        <div id="header-user-notification-click" class="noselect">
                            <i class="far fa-bell"></i>
                            <span class="user-notification-count">3</span>
                        </div>
                        <div id="header-user-notification-menu">
                            <h3>Notifications</h3>
                            <div id="header-notification-menu-padding">
                                    <ul id="header-user-notification-list">
                                        { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                    </ul>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                    </div>
                    
                    <div id="header-user-create">
                        <div id="header-user-create-click" class="noselect">
                            <NavLink to="/dashboard-video-upload">
                                <img src="/assets/images/create.png" alt="Create"/>
                            </NavLink>
                        </div>
                    </div>
                    
                    
                    <div class="clearfix"></div>
                    
                    <nav id="mobile-navigation-pro">
                    
                        <ul id="mobile-menu-pro">
                        <li>
                            <NavLink to="/dashboard">
                                <span class="icon-Fire-Flame"></span>
                            Popular
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                Subscriptions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
                        <li>
                            <a href="#!">
                                <i class="far fa-bell"></i>
                                <span class="user-notification-count">3</span>
                            Notifications
                            </a>
                        </li>
                        </ul>
                        <div class="clearfix"></div>
                        
                        <div id="search-mobile-nav-pro">
                            <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                        </div>
                        
                    </nav>
                    
                </header>
                
                
                
                <nav id="sidebar-nav">
                    <ul id="vertical-sidebar-nav" class="sf-menu">
                    <li class="normal-item-pro">  
                        <NavLink to="/dashboard">
                              <span class="icon-Fire-Flame"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>

                    </ul>
                        <div class="clearfix"></div>
                </nav>

                <main id="col-main">
                    
                    
                    
                    <div class="dashboard-container">
                        
                        <h4 class="heading-extra-margin-bottom">Search Results for: {this.state.searchText}</h4>
                        <div class="row">
                        { this.state.users.map((user) => {
                                return(
                                    <div class="col-12 col-md-6 col-lg-4 col-xl-3" key={user.key}>
                                        <div class="item-listing-container-skrn">
                                        <NavLink to={{ pathname: '/dashboard-profile/' + user.userID, userProps: {user: user.userID}}}><img src={ user.profilePictureFileName === '' ? "https://via.placeholder.com/500x707" : user.profilePictureFileName} alt="User"/></NavLink>
                                            <div class="item-listing-text-skrn">
                                                <div class="item-listing-text-skrn-vertical-align"><h6><NavLink to={{ pathname: '/dashboard-profile/' + user.userID, userProps: {user: user.userID}}}>{user.fullName}</NavLink></h6>
                                                    <div
                                                    class="circle-rating-pro"
                                                    data-value="0.86"
                                                    data-animation-start-value="0.86"
                                                    data-size="32"
                                                    data-thickness="3"
                                                    data-fill="{
                                                        &quot;color&quot;: &quot;#42b740&quot;
                                                    }"
                                                    data-empty-fill="#def6de"
                                                    data-reverse="true"
                                                    ><span style={{ color:'#42b740' }}>8.6</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                )
                             })
                            }

                            { this.state.robs.map((rob) => {
                                console.log("rob.ownerID: ", rob.ownerID);
                                console.log("rob.robID: ", rob.robID);
                                return(
                                    <div class="col-12 col-md-6 col-lg-4 col-xl-3" key={rob.key}>
                                        <div class="item-listing-container-skrn">
                                        <NavLink to={{ pathname: '/dashboard-movie-profile/' + rob.robID, robProps: {robID: rob.robID, ownerID: rob.ownerID }}}><img src={ rob.screenshotURL === '' ? "https://via.placeholder.com/450x620" : rob.screenshotURL } alt="Listing"/></NavLink>
                                            <div class="item-listing-text-skrn">
                                                <div class="item-listing-text-skrn-vertical-align"><h6><NavLink to={{ pathname: '/dashboard-movie-profile/' + rob.robID, robProps: {robID: rob.robID, ownerID: rob.ownerID }}}>{rob.title}</NavLink></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                             })
                            }
                        </div>
                        
                        
                        <ul class="page-numbers">
                            <li><a class="previous page-numbers" href="#!"><i class="fas fa-chevron-left"></i></a></li>
                            <li><span class="page-numbers current">1</span></li>
                            <li><a class="page-numbers" href="#!">2</a></li>
                            <li><a class="page-numbers" href="#!">3</a></li>
                            <li><a class="page-numbers" href="#!">4</a></li>
                            <li><a class="next page-numbers" href="#!"><i class="fas fa-chevron-right"></i></a></li>
                        </ul>       
                    </div>
                </main>
            </div>
        </div>
        )
    }
}
</Consumer>
)
}
} 

export default withRouter(DashboardSearch);