import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import HomeNavbar from '../layout/HomeNavBar';

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class SignUpWelcome extends React.Component  {
	componentWillMount() {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/script.js");
    }

    render() {
        return (
            <div className="App">
				<HomeNavbar />
                <div id="content-pro">
			

			
			<div id="pricing-plans-background-image">
				<div class="container">
						<div class="registration-steps-page-container">
							

							<div class="registration-step-final-padding welcome-page-styles">
								
								<div class="centered-headings-pro pricing-plans-headings">
									<h6>Sign up compvare!</h6>
									<h1>Welcome to RobberBaron!</h1>
								</div>
								
								<h6 class="welcome-style-summary">You can now start watching Movies and TV shows.</h6>
								<h6 class="welcome-style-summary">Your next billing information will be sent on your email on July 30, 2017</h6>
								
								<h3 class="welcome-plan-title">Your Plan: <span>Premium</span></h3>
								
								<ul class="welcome-features">
									<li>Ultra HD Available</li>
									<li>70 movies and shows</li>
									<li>Watch on any Device</li>
									<li>Download Available</li>
								</ul>
								<div class="clearfix"></div>
								
								<div class="registration-step-final-footer">
									<a href="dashboard-home.html" class="btn btn-green-pro">Start Watching</a>
									<a href="#!" class="btn">Verify Your Email</a>
								</div>
								
							</div>
	  					 
							
						</div>
					
				</div>
			</div>
			
		</div>
		
		<footer id="footer-pro">
			<div class="container">
				<div class="row">
					<div class="col-md">
						<div class="copyright-text-pro">&copy; Copyright 2021 <a href="https://getspreebie.com/" target="_blank">Spreebie, Inc</a>. All Rights Reserved</div>
					</div>
					<div class="col-md">
						<ul class="social-icons-pro">
							<li class="facebook-color"><a href="https://facebook.com/progressionstudios" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
							<li class="twitter-color"><a href="https://twitter.com/Progression_S" target="_blank"><i class="fab fa-twitter"></i></a></li>
							<li class="youtube-color"><a href="https://youtube.com" target="_blank"><i class="fab fa-youtube"></i></a></li>
							<li class="vimeo-color"><a href="https://vimeo.com" target="_blank"><i class="fab fa-vimeo-v"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
		
		<a href="#0" id="pro-scroll-top"><i class="fas fa-chevron-up"></i></a>
		
		
		<div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true">
			 <button type="button" class="close float-close-pro" data-dismiss="modal" aria-label="Close">
			           <span aria-hidden="true">&times;</span>
			</button>
		  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
		    <div class="modal-content">
				 <div class="modal-header-pro">
					 <h2>Welcome Back</h2>
					 <h6>Sign in to your account to continue using RobberBaron.</h6>
				 </div>
				 <div class="modal-body-pro social-login-modal-body-pro">
					 
					 <div class="registration-social-login-container">
						 <form>
							 <div class="form-group">
								 <input type="text" class="form-control" id="username" placeholder="Username"/>
							 </div>
							 <div class="form-group">
								 <input type="password" class="form-control" id="password" placeholder="Password"/>
							 </div>
							 <div class="form-group">
								 <button type="button" class="btn btn-green-pro btn-display-block">Sign In</button>
							 </div>
							 <div class="container-fluid">
								 <div class="row no-gutters">
							 		<div class="col checkbox-remember-pro"><input type="checkbox" id="checkbox-remember"/><label for="checkbox-remember" class="col-form-label">Remember me</label></div>
									<div class="col forgot-your-password"><a href="#!">Forgot your password?</a></div>
								</div>
							</div>
						
						 </form>
					 
						 <div class="registration-social-login-or">or</div>
						 
					 </div>
					 
					 <div class="registration-social-login-options">
					 	<h6>Sign in with your social account</h6>
						<div class="social-icon-login facebook-color"><i class="fab fa-facebook-f"></i> Facebook</div>
						<div class="social-icon-login twitter-color"><i class="fab fa-twitter"></i> Twitter</div>
						<div class="social-icon-login google-color"><i class="fab fa-google-plus-g"></i> Google</div>
					 </div>
					 
					 <div class="clearfix"></div>
					 

 		      </div>
				
			 <a class="not-a-member-pro" href="signup-step2.html">Not a member? <span>Join Today!</span></a>
		    </div>
		  </div>
		</div>
            </div>
        )
    }
}

export default withRouter(SignUpWelcome);