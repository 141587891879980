import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import HomeNavbar from '../layout/HomeNavBar';
import firebase from '../../firebase';
import { Consumer } from '../../context';
import PayPalBtn from '../classes/PayPalBtn';
import { loadStripe } from '@stripe/stripe-js';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class SignUpStep3 extends React.Component  {
    state = {
        loginEmail: '',
        loginPassword: '',
        email: '',
        password: '',
        isLoggedIn: false,
        userID: '',
        fullName: '',
        plan: 'Starter',
        signupEmail: '',
        signUpFirstName: '',
        signUpLastName: '',
        signUpPassword: '',
        signUpUserID: '',
        profilePictureFileName: '',
        readyForSignup: false,
        subscriptionID: ''
    }
    
    componentWillMount() {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/script.js");

        if (this.props.location.signUpProps) {
            console.log("Sign Up Props: ", this.props.location.signUpProps);
            var { plan, email, firstName, lastName, password, userID } =  this.props.location.signUpProps;

            if (plan === 'Free') {
                this.setState({
                    ...this.state,
                    plan,
                    signupEmail: email,
                    signUpFirstName: firstName,
                    signUpLastName: lastName,
                    signUpPassword: password,
                    signUpUserID: userID,
                    readyForSignup: true
                });
            } else {
                this.setState({
                    ...this.state,
                    plan,
                    signupEmail: email,
                    signUpFirstName: firstName,
                    signUpLastName: lastName,
                    signUpPassword: password,
                    signUpUserID: userID
                });
            }
        }
    }

    handleSubmit = (dispatch, e) => {
        e.preventDefault()

        if (e.target.id === 'loginForm') {
            console.log('Submit button clicked!');
            $('#signInButton').text('Please Wait');
            $('#signInButton').prop('disabled', true);

            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                .then((user) => {
                    var user = firebase.auth().currentUser;
                    var userRef = firebase.firestore().collection('users').doc(user.uid)

                    userRef.get().then((user) => {
                        if (user.exists) {
                            const { fullName, userID } = user.data()

                            dispatch({
                                type: 'USER_STATE',
                                payload: {
                                    isLoggedIn: true,
                                    userID,
                                    fullName
                                }
                            });

                            this.setState({
                                ...this.state,
                                isLoggedIn: true,
                                userID,
                                fullName
                            });              
                        } else {
                            console.log("No such document!");
                        }
                    }).catch(function(error) {
                        console.log("Error getting document:", error);
                    });
                }, function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log('Error: ' + error.code + ' ', errorMessage);

                    if (errorCode === "auth/user-not-found") {
                        $('#loginFormMessage').text('The email you entered is wrong. Please try again.');
                    } else if (errorCode === "auth/wrong-password") {
                        $('#loginFormMessage').text('The password is wrong. Please try again.');
                    } else {
                        $('#loginFormMessage').text('There was an error logging. Please try again.');
                    }

                    $('#signInButton').text('Sign In');
                    $('#signInButton').prop('disabled', false);
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                });
        } else {
            // Do nothing
        }
    }

    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({
            [e.target.id]: e.target.value
        })

        $('#loginFormMessage').css('color', '#000000');
        $('#loginFormMessage').text('Sign in to your account to continue using RobberBaron');
    }

    handleClick = (dispatch, e) => {
        e.preventDefault()
        console.log('Facebook button clicked')

        if (e.target.id === 'facebookSignIn') { 
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user);

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });             
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
        } else if (e.target.id === 'forgotPassword') {
            if (this.state.email === '') {
                $('#loginFormMessage').text('Please fill in your email.');
                    
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            } else {
                var auth = firebase.auth();
                var emailAddress = this.state.email

                auth.sendPasswordResetEmail(emailAddress).then(function() {
                    $('#loginFormMessage').text('Your password has been reset. Please check your email.');
                }).catch(function(error) {
                    $('#loginFormMessage').text('There was an error reseting your password. Please try again.');
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                });
            }
        } else {
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });               
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
              }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
        }
    }

    createPremiumSubscription = (subscriptionID, userID) => {
        firebase
            .firestore()
            .collection('premiumSubscriptions')
            .doc(userID)
            .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                subscriptionID,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                userID
            })
    }

    sendToCheckout = async (e) => {
        e.preventDefault();

        if (this.state.plan === 'Free') {
            this.goToDashboard();
        } else {
              // had to update firebase.firestore() to firebase.default.firestore() (from stripe firebase extension docs)
            if (this.state.signUpUserID) {
                $('#payToSubscribeButton').text('Please Wait');
                $('#payToSubscribeButton').prop('disabled', true);

                firebase
                    .firestore()
                    .collection('users')
                    .doc(this.state.signUpUserID)
                    .collection('checkout_sessions')
                    .add({
                        price: 'price_1In69mEhuWeYDq5DLeeVrpUg', // todo price Id from your products price in the Stripe Dashboard
                        success_url: window.location.origin, // return user to this screen on successful purchase
                        cancel_url: window.location.origin // return user to this screen on failed purchase
                    })
                    .then((docRef) => {
                        // Wait for the checkoutSession to get attached by the extension
                        docRef.onSnapshot(async (snap) => {
                        const { error, sessionId } = snap.data();
                        if (error) {
                            // Show an error to your customer and inspect
                            // your Cloud Function logs in the Firebase console.
                            alert(`An error occurred: ${error.message}`);
                        }

                        if (sessionId) {
                            // We have a session, let's redirect to Checkout
                            // Init Stripe
                            const stripe = await loadStripe(
                            'pk_live_Nnl12K4jTmYq564NWV0aPUCT' // todo enter your public stripe key here
                            );
                            console.log(`redirecting`);
                            await stripe.redirectToCheckout({ sessionId });
                        }
                        });
                    });
            }
        }
    }

    goToDashboard = () => {
        this.props.history.push('/dashboard');
    }
    
    render() {
        console.log('The state: ', this.state);
        const { plan, isLoggedIn } = this.state;

        var planDetails;
        var paypalButton = '';
        var finalButton = '';

        if (plan === 'Free') {
            planDetails = <h3>Free: <span class="total">$0</span><span class="duration">/month</span></h3>;
            finalButton = <button class="btn btn-green-pro" id="signUpButton">Finish</button>;
        } else {
            planDetails = <div><h3>Starter: <span class="total">$6</span><span class="duration">/month.</span></h3>
                               <h3>$6 <span class="duration">amount charged is paid to </span>Spreebie, Inc.</h3>
                          </div>
            finalButton = <button class="btn btn-green-pro" id="payToSubscribeButton">Pay To Subscribe</button>;
            paypalButton = <PayPalBtn
                                amount = "<amount>"
                                currency = "USD"
                                createSubscription={this.paypalSubscribe}
                                onApprove={this.paypalOnApprove}
                                catchError={this.paypalOnError}
                                onError={this.paypalOnError}
                                onCancel={this.paypalOnError}
                            />
        }

        if (isLoggedIn) {
            this.props.history.push('/dashboard');
        }

        return (
            <Consumer>
                {value => {
                    const { dispatch } = value;
        return (
            <div className="App">
                <HomeNavbar />
                <div id="content-pro">
			
            <div class="container">
                 <div class="centered-headings-pro pricing-plans-headings">
                     <h6>For one low monthly price</h6>
                     <h1>Instantly watch Premium Content From Your Favorite Creator</h1>
                 </div>
         </div>
         
         <div class="container">
             <ul id="registration-steps-pro">
                 <li>
                    <NavLink to="/signup-step1"> 
                         <div class="registration-step-circle-icon">01</div>
                         <div class="registration-step-number">Step 1</div>
                         <h5 class="registration-step-title">Choose your plan</h5>
                    </NavLink>
                 </li>
                 <li>
                    <NavLink to="/signup-step2">
                         <div class="registration-step-circle-icon">02</div>
                         <div class="registration-step-number">Step 2</div>
                         <h5 class="registration-step-title">Create your account</h5>
                    </NavLink>
                 </li>
                 <li class="current-menu-item">
                    <NavLink to="/signup-step3">
                         <div class="registration-step-circle-icon">03</div>
                         <div class="registration-step-number">Step 3</div>
                         <h5 class="registration-step-title">Billing information</h5>
                    </NavLink>
                 </li>
             </ul>
             <div class="clearfix"></div>
         </div>
         
         
         <div id="pricing-plans-background-image">
             <div class="container">
                     <div class="registration-steps-page-container">
                         
                          <div class="registration-billing-form">                             
                             <div class="row">
                                      <div class="billing-form-pro">
                                        <br></br>
                                        <form onSubmit={this.sendToCheckout.bind(this)}>                                          
                                            <div class="form-group">
                                                <h6 id="signupFormMessage"></h6>
                                              
                                              <div class="billing-plan-container">
                                                  {planDetails}
                                              </div>
                                                { finalButton }
                                                <div class="clearfix"></div>
                                            </div>
                                        </form>
                                      </div>
                             </div>
                             
                          </div>
                         
                     </div>
                 
             </div>
         </div>
         
     </div>
     
     <footer id="footer-pro">
         <div class="container">
             <div class="row">
                 <div class="col-md">
                     <div class="copyright-text-pro">&copy; Copyright 2021 <a href="https://getspreebie.com/" target="_blank">Spreebie, Inc</a>. All Rights Reserved</div>
                 </div>
                 <div class="col-md">
                    <ul class="social-icons-pro">
                        <li class="facebook-color"><a href="https://web.facebook.com/spreebieapp/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="twitter-color"><a href="https://twitter.com/thabodavidklass?lang=en" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li class="youtube-color"><a href="https://www.youtube.com/channel/UCv8WK-BgICp2Z4NaSuWJ7TA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li class="vimeo-color"><a href="https://vimeo.com/user52673013" target="_blank"><i class="fab fa-vimeo-v"></i></a></li>
                    </ul>
                </div>
             </div>
         </div>
     </footer>
     
     <a href="#0" id="pro-scroll-top"><i class="fas fa-chevron-up"></i></a>
     
     
     
     <div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true">
                                <button type="button" class="close float-close-pro" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header-pro">
                                            <h2>Welcome Back</h2>
                                            <h6 id="loginFormMessage">Sign in to your account to continue using RobberBaron</h6>
                                        </div>
                                        <div class="modal-body-pro social-login-modal-body-pro">
                                            <div class="registration-social-login-container">
                                                <form onSubmit={this.handleSubmit.bind(this, dispatch)}>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="email" placeholder="Username" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" id="password" placeholder="Password" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-green-pro btn-display-block" id="signInButton">Sign In</button>
                                                    </div>
                                                    <div class="container-fluid">
                                                        <div class="row no-gutters">
                                                        <div class="col checkbox-remember-pro"><input type="checkbox" id="checkbox-remember"/><label for="checkbox-remember" class="col-form-label">Remember me</label></div>
                                                        <div class="col forgot-your-password"><a href="#!" onClick={this.handleClick} type="submit" id="forgotPassword">Forgot your password?</a></div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="registration-social-login-or"></div>
                                            </div>
                                            <div class="registration-social-login-options">
                                                <br />
                                                <h6>Watch premium content from your favorite creators</h6>
                                                <br />
                                                <img src="assets/images/logo_big.png" />
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
        )
    }}
    </Consumer>
)
    }
}

export default withRouter(SignUpStep3);