import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Spinner from 'react-spinner-material';
import { Consumer } from '../../context';
import ReactPlayer from "react-player";
import firebase from '../../firebase';
import Notification from '../classes/Notification';
import $ from 'jquery';
require('firebase/auth');
require('firebase/storage');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = false;
  document.body.appendChild(script);
}

class DashboardVideoUpload extends React.Component  {
    state = {
        videoURL: '',
        screenshotURL: '',
        robID: '',
        title: '',
        description: '',
        tags: '',
        userID: '',
        fullName: '',
        dataLoaded: false,
        genre: 'Comedy',
        searchText: '',
        profilePictureFileName: '',
        profilePictureFileNameLoaded: false,
        subs: [],
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false,
        isUpdatingScreenshot: false
    }

    componentDidMount () {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/jquery-asRange.min.js");
        appendScript("assets/js/circle-progress.min.js");
        appendScript("assets/js/afterglow.min.js");
        appendScript("assets/js/script.js");
        appendScript("assets/js/script-dashboard.js");
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: ''
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    handleChange = (e) => {
        $('#saveRobText').css('color', '#000000');
        $('#saveRobText').text('Click "Save Rob" to upload your new Rob (video).');
        if (e.target.id === "screenshotURL") {
            this.setState({
                ...this.state,
                isUpdatingScreenshot: true
            })

            var storageRef = firebase.storage().ref(this.generateUUID())
            var screenshotFile = e.target.files[0] // upload the first file only
            
            storageRef
                .put(screenshotFile)
                .then(snapshot => {
                    snapshot.ref.getDownloadURL().then(url => {
                        this.setState({
                            ...this.state,
                            screenshotURL: url,
                            isUpdatingScreenshot: false
                        })
                    })
                })
        } else {
            this.setState({
                [e.target.id]: e.target.value
            });

            if (e.target.id === 'searchText') {            
                this.props.history.push('/dashboard-search/' + e.target.value);
            }
        }
    }

    handleClick = (e) => {
        e.preventDefault();

        if (e.currentTarget.id === "filmGenre") {
            this.setState({
                ...this.state,
                genre: 'Film'
            })
        } else if (e.currentTarget.id === "carsGenre") {
            this.setState({
                ...this.state,
                genre: 'Cars'
            })
        } else if (e.currentTarget.id === "musicGenre") {
            this.setState({
                ...this.state,
                genre: 'Music'
            })
        } else if (e.currentTarget.id === "animalsGenre") {
            this.setState({
                ...this.state,
                genre: 'Animals'
            })
        } else if (e.currentTarget.id === "sportsGenre") {
            this.setState({
                ...this.state,
                genre: 'Sports'
            })
        } else if (e.currentTarget.id === "travelGenre") {
            this.setState({
                ...this.state,
                genre: 'Travel'
            })
        } else if (e.currentTarget.id === "gamingGenre") {
            this.setState({
                ...this.state,
                genre: 'Gaming'
            })
        } else if (e.currentTarget.id === "comedyGenre") {
            this.setState({
                ...this.state,
                genre: 'Comedy'
            })
        } else if (e.currentTarget.id === "politicsGenre") {
            this.setState({
                ...this.state,
                genre: 'Politics'
            })
        } else if (e.currentTarget.id === "educationGenre") {
            this.setState({
                ...this.state,
                genre: 'Education'
            })
        } else if (e.currentTarget.id === "scienceGenre") {
            this.setState({
                ...this.state,
                genre: 'Science'
            })
        } else if (e.currentTarget.id === "otherGenre") {
            this.setState({
                ...this.state,
                genre: 'Other'
            })
        } else {
            if (this.state.videoURL === '') {
                $('#saveRobText').css('color', '#FF0000');
                $('#saveRobText').text('Please enter your video link.');
            } else if (this.state.screenshotURL === '') {
                $('#saveRobText').css('color', '#FF0000');
                $('#saveRobText').text('Click the "Choose File" button to choose your screenshot.');
            } else if (this.state.title === '') {
                $('#saveRobText').css('color', '#FF0000');
                $('#saveRobText').text('Please enter your video title.');
            } else if (this.state.description === '') {
                $('#saveRobText').css('color', '#FF0000');
                $('#saveRobText').text('Please enter your video description.');
            } else {
                this.setRobData(this.state.userID, this.state.videoURL, this.state.screenshotURL, this.state.title, this.state.description, this.state.tags, this.state.genre);
            }
        }
    }

    setRobData = (userID, videoURL, screenshotURL, title, description, tags, genre) => {
        var robUserRef = firebase.firestore().collection('robs').doc(userID).collection('data').doc();
        var localRobID = robUserRef.id;
        var firstvarterCapitalized = title.charAt(0).toUpperCase();

        robUserRef
            .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                videoURL,
                screenshotURL,
                title,
                description,
                tags,
                genre,
                userID,
                robID: localRobID,
                searchKey: firstvarterCapitalized,
                updateAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                this.setState({
                    ...this.state,
                    robID: localRobID
                })

                this.setNotificationData(userID, title, localRobID);

                firebase.firestore()
                    .collection('robMeta')
                    .doc(localRobID)
                    .set({
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        description,
                        genre,
                        ownerID: userID,
                        robID: localRobID,
                        screenshotURL,
                        views: 0,
                        tags,
                        title,
                        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                        videoURL
                    });

                firebase.firestore()
                    .collection('robSearch')
                    .doc(localRobID)
                    .set({
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        description,
                        genre,
                        ownerID: userID,
                        robID: localRobID,
                        screenshotURL,
                        searchKey: firstvarterCapitalized,
                        tags,
                        title,
                        updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                        videoURL
                    })
                    .then(() => {
                        this.props.history.push('/dashboard-mine');
                    });
            })
    }

    generateUUID = () => { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depvared
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    getUserData = () => {
        if (this.state.userID) {
            var userRef = firebase.firestore().collection('users').doc(this.state.userID)

            userRef.get().then((user) => {
                if (user.exists) {
                    const { profilePictureFileName } = user.data()

                    this.setState({
                        ...this.state,
                        profilePictureFileNameLoaded: true,
                        profilePictureFileName
                    });
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    }

    setNotificationData = (robOwnerID, robTitle, robID) => {
        var notificationRef = firebase.firestore().collection('notifications').doc(robOwnerID).collection('data').doc();

        notificationRef
            .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                robOwnerID,
                robTitle,
                robID,
                updateAt: firebase.firestore.FieldValue.serverTimestamp()
            })
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                })

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }
    
    render() {
        const isUpdatingScreenshot = this.state.isUpdatingScreenshot;
        var pictureSection;
        if (isUpdatingScreenshot) {
            pictureSection = <div id="robberBaronSpinnerDiv"><Spinner size={120} spinnerColor={"#333"} spinnerWidth={4} visible={true} /></div>;
        } else {
            pictureSection = <div id="robberBaronScreenshotDiv"><img src={this.state.screenshotURL == '' ? "https://firebasestorage.googleapis.com/v0/b/robberbarontv.appspot.com/o/upload-screenshot.png?alt=media&token=fe14e9f8-883f-4a52-8a16-7fafbc817de8" : this.state.screenshotURL } alt="Account Image"></img></div>;
        }

        return (
            <Consumer>
                {value => {
                    const { isLoggedIn, userID, dispatch } = value;

                    var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                    if (!persistedState || !persistedState.userID || persistedState.userID === '') {
                        this.props.history.push('/');
                    } else {
                        if (!this.state.profilePictureFileNameLoaded) {
                            this.getUserData();
                        }

                        if (userID === persistedState.userID) {
                            if (!this.state.dataLoaded) {
                                this.setState({
                                    ...this.state,
                                    dataLoaded: true,
                                    userID,
                                    fullName: persistedState.fullName
                                })
                            }   
                        }

                        if (userID === persistedState.userID) {
                            if (!this.state.subsLoaded) {    
                                this.getSubs(userID);
                            } else {
                                if (!this.state.notificationsLoaded) {
                                    this.getNotifications(this.state.subs);
                                }
                            }
                        }
                    }

                    return (
                        <div className="App">
                        <div id="sidebar-bg">	
                            <header id="videohead-pro" class="sticky-header">
                                <div id="video-logo-background"><Link to="/dashboard"><img src="assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                                
                                <div id="video-search-header">
                                    <div id="search-icon-more"></div>
                                    <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                                </div>
                                
                                <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                                
                                
                                <div id="header-user-profile">
                                    <div id="header-user-profile-click" class="noselect">
                                        <img src={this.state.profilePictureFileName} />
                                        <div id="header-username">{this.state.fullName}</div><i class="fas fa-angle-down"></i>
                                    </div>
                                    <div id="header-user-profile-menu">
                                        <ul>
                                            <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                                            <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                                            <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                                            <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                                            <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div id="header-user-notification">
                                    <div id="header-user-notification-click" class="noselect">
                                        <i class="far fa-bell"></i>
                                        <span class="user-notification-count">3</span>
                                    </div>
                                    <div id="header-user-notification-menu">
                                        <h3>Notifications</h3>
                                        <div id="header-notification-menu-padding">
                                                <ul id="header-user-notification-list">
                                                    { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                </div>
                                
                                <div id="header-user-create">
                                    <div id="header-user-create-click" class="noselect">
                                        <NavLink to="/dashboard-video-upload">
                                            <img src="assets/images/create.png" alt="Create"/>
                                        </NavLink>
                                    </div>
                                </div>
                                
                                
                                <div class="clearfix"></div>
                                
                                <nav id="mobile-navigation-pro">
                                
                                    <ul id="mobile-menu-pro">
                                    <li>
                            <NavLink to="/dashboard">
                                <span class="icon-Fire-Flame"></span>
                            Popular
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                Subscriptions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>
                                    <li>
                                        <a href="#!">
                                            <i class="far fa-bell"></i>
                                            <span class="user-notification-count">3</span>
                                        Notifications
                                        </a>
                                    </li>
                                    </ul>
                                    <div class="clearfix"></div>
                                    
                                    <div id="search-mobile-nav-pro">
                                        <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                                    </div>
                                    
                                </nav>
                                
                            </header>
                            
                            
                            
                            <nav id="sidebar-nav">
                                <ul id="vertical-sidebar-nav" class="sf-menu">
                                <li class="normal-item-pro">  
                        <NavLink to="/dashboard">
                              <span class="icon-Fire-Flame"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-RSS"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Paper-Plane"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Profile"></span>
                                Mine
                            </NavLink>
                        </li>

                                </ul>
                                    <div class="clearfix"></div>
                            </nav>

                            <main id="col-main">
                                
                                <div class="flexslider progression-studios-dashboard-slider">
                                <div class="progression-studios-slider-dashboard-image-background" style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/robberbarontv.appspot.com/o/create-your-rob.png?alt=media&token=d1675b89-3f17-41a1-bf95-4bece6e721cf)' }}>
                                <ReactPlayer width="1120px" height="560px" controls="true"
                                                                    url={this.state.videoURL}
                                                                />
                                </div>
                                </div>

                                <ul class="dashboard-genres-pro">
                                    <li class={ this.state.genre !== 'Film' ? '' : 'active' } type="submit" id="filmGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/film.png" alt="Film"/>
                                        <h6>Film</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Cars' ? '' : 'active' } type="submit" id="carsGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/cars.png" alt="Cars"/>
                                        <h6>Cars</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Music' ? '' : 'active' } type="submit" id="musicGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/music.png" alt="Music"/>
                                        <h6>Music</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Animals' ? '' : 'active' } type="submit" id="animalsGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/animals.png" alt="Animals"/>
                                        <h6>Animals</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Sports' ? '' : 'active' } type="submit" id="sportsGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/sports.png" alt="Sports"/>
                                        <h6>Sports</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Travel' ? '' : 'active' } type="submit" id="travelGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/travel.png" alt="Travel"/>
                                        <h6>Travel</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Gaming' ? '' : 'active' } type="submit" id="gamingGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/gaming.png" alt="Gaming"/>
                                        <h6>Gaming</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Comedy' ? '' : 'active' } type="submit" id="comedyGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/comedy.png" alt="Comedy"/>
                                        <h6>Comedy</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Politics' ? '' : 'active' } type="submit" id="politicsGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/politics.png" alt="Politics"/>
                                        <h6>Politics</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Education' ? '' : 'active' } type="submit" id="educationGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/education.png" alt="Education"/>
                                        <h6>Education</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Science' ? '' : 'active' } type="submit" id="scienceGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/science.png" alt="Science"/>
                                        <h6>Science</h6>
                                    </li>
                                    <li class={ this.state.genre !== 'Other' ? '' : 'active' } type="submit" id="otherGenre" onClick={this.handleClick}>
                                        <img src="assets/images/categories/other.png" alt="Other"/>
                                        <h6>Other</h6>
                                    </li>
                                </ul>
                                
                                <div class="clearfix"></div>

                                <div class="container-fluid">
                                    <div class="row">
                                    
                                        <div class="col-12  col-lg-3">
                                            <div id="account-edit-photo">
                                                {pictureSection}
                                                <input type="file" id="screenshotURL" accept="image/*" onChange={this.handleChange} style={{ width: '200px'}}/>
                                            </div>
                                        </div>
                                        <div class="col">
                                        
                                            <form class="account-settings-form" onSubmit={this.handleSubmit}>
                                        
                                            <h5>Address, Title and Screehsot</h5>
                                            <p class="small-paragraph-spacing">Enter your title and UNLISTED video link from YouTube, Vimeo, DailyMotion or Twitch.</p>
                                            <div class="row">
                                                <div class="col-sm">
                                                        <div class="form-group">
                                                        <label for="first-name" class="col-form-label">Paste Your UNLISTED Video's Link:</label>
                                                            <input type="text" class="form-control" id="videoURL" placeholder="e.g. https://www.youtube.com/watch?v=9q4Ph3YrOiQ" onChange={this.handleChange}/>
                                                        </div>
                                                </div>
                                                <div class="col-sm">
                                                        <div class="form-group">
                                                            <label for="last-name" class="col-form-label">Title:</label>
                                                            <input type="text" class="form-control" id="title" onChange={this.handleChange}/>
                                                        </div>
                                                </div>
                                            </div>
                                            <hr/>
                                        
                                            <h5>Description</h5>
                                            <p class="small-paragraph-spacing">You can change the email address you use here.</p>
                                            
                                            <div class="row">
                                                <div class="col-sm">
                                                        <div class="form-group">
                                                        <label for="e-mail" class="col-form-label">Tags (separated by commas)</label>
                                                            <input type="text" class="form-control" id="tags" onChange={this.handleChange}/>
                                                            <br />
                                                            <label for="exampleFormControlTextarea1">Description</label>
                                                            <textarea class="form-control" id="description" rows="10" onChange={this.handleChange}></textarea>
                                                        </div>
                                                </div>
                                                <div class="col-sm">
                                                        <div class="form-group">
                                                            <div><label for="button-change" class="col-form-label">&nbsp; &nbsp;</label></div>
                                                            <a href="#!" class="btn btn-form" onClick={this.handleClick} type="submit"><i class="fas fa-save"></i> Save Rob</a>
                                                            <p class="small-paragraph-spacing"></p>
                                                            <p id="saveRobText"class="small-paragraph-spacing">Click "Save Rob" to upload your new Rob (video).</p>
                                                        </div>
                                                </div>
                                                
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                    )
                }
            }
        </Consumer>
        )
    }
} 

export default withRouter(DashboardVideoUpload);