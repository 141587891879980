import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

class HomeNavBar extends React.Component {
    render() {
        var homeClass = 'normal-item-pro';
        var signUpStepClass = 'normal-item-pro';
        var faqClass = 'normal-item-pro';

        if (this.props.location.pathname === "/") {
            homeClass = homeClass + ' current-menu-item';
        } else if (this.props.location.pathname === "/faqs") {
            faqClass = faqClass + ' current-menu-item';
        } else {
            signUpStepClass = signUpStepClass + ' current-menu-item';
        }

        return (
            <header id="masthead-pro">
                <div class="container">
                
                <h1><Link to=""><img src="assets/images/logo.png" alt="Logo"/></Link></h1>
                
                    <nav id="site-navigation-pro">
                        <ul class="sf-menu">
                            <li class={homeClass}>
                                <Link to="">Home</Link>
                            </li>
                            {/*<li class="normal-item-pro">
                                <NavLink to="/dashboard">Dashboard</NavLink>
                                <ul class="sub-menu">
                                    <li class="normal-item-pro">
                                        <a href="#!">Sub-menu item 1</a>
                                    </li>
                                    <li class="normal-item-pro">
                                        <a href="#!">Sub-menu item 2</a>
                                    </li>
                                    <li class="normal-item-pro">
                                        <a href="#!">Sub-menu item 3</a>
                                    </li>
                                </ul> 
                            </li>*/}
                            <li class={signUpStepClass} style={{color: '#FF0000'}}>
                                <NavLink to="/signup-step1" style={{color: '#ff0000'}}>Start Now</NavLink>
                            </li>
                            <li class={faqClass}>
                                <NavLink to="/faqs">FAQs</NavLink>
                            </li>
                        </ul>
                    </nav>
                    
                    <button class="btn btn-header-pro noselect" data-toggle="modal" data-target="#LoginModal" role="button">Sign In</button>
                    
                    <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                    
                    <div class="clearfix"></div>
                    
                    <nav id="mobile-navigation-pro">
                    
                        <ul id="mobile-menu-pro">
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>
                                <a href="dashboard-home.html">New Releases</a>
                                <ul>
                                    <li class="normal-item-pro">
                                        <a href="#!">Sub-menu item 1</a>
                                    </li>
                                    <li class="normal-item-pro">
                                        <a href="#!">Sub-menu item 2</a>
                                    </li>
                                    <li class="normal-item-pro">
                                        <a href="#!">Sub-menu item 3</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="signup-step1.html">Pricing Plans</a>
                            </li>
                            <li>
                                <a href="faqs.html">FAQs</a>
                            </li>
                        </ul>
                        <div class="clearfix"></div>
                    
                        <button class="btn btn-mobile-pro btn-green-pro noselect" data-toggle="modal" data-target="#LoginModal" role="button">Sign In</button>
                    
                    </nav>
                </div>
            </header>
        )
    }
}

export default withRouter(HomeNavBar);