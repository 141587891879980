import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import HomeNavbar from '../layout/HomeNavBar';
import firebase from '../../firebase';
import { Consumer } from '../../context';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class FAQs extends React.Component {
    state = {
        email: '',
        password: '',
        isLoggedIn: false,
        userID: '',
        fullName: ''
    }

    componentWillMount() {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/script.js");

        this.setState({
            ...this.state,
            isLoggedIn: false,
            userID: '',
            fullName: ''
        });

        localStorage.setItem('robberbaron', JSON.stringify(this.state));
    }

    handleSubmit = (dispatch, e) => {
        e.preventDefault()

        console.log('Submit button clicked!');
        $('#signInButton').text('Please Wait');
        $('#signInButton').prop('disabled', true);

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .then((user) => {
                var user = firebase.auth().currentUser;  
                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });               
                    } else {
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            }, function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log('Error: ' + error.code + ' ', errorMessage);

                if (errorCode === "auth/user-not-found") {
                    $('#loginFormMessage').text('The email you entered is wrong. Please try again.');
                } else if (errorCode === "auth/wrong-password") {
                    $('#loginFormMessage').text('The password is wrong. Please try again.');
                } else {
                    $('#loginFormMessage').text('There was an error logging. Please try again.');
                }

                $('#signInButton').text('Sign In');
                $('#signInButton').prop('disabled', false);
                
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            });
    }

    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({
            [e.target.id]: e.target.value
        })

        $('#loginFormMessage').css('color', '#000000');
        $('#loginFormMessage').text('Sign in to your account to continue using RobberBaron');
    }

    handleClick = (dispatch, e) => {
        e.preventDefault();

        if (e.target.id === 'facebookSignIn') { 
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user);

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });               
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
        } else if (e.target.id === 'goToSignUpLink') {
            $(".float-close-pro").click();
            this.props.history.push('/signup-step1');
        } else if (e.target.id === 'forgotPassword') {
            if (this.state.email === '') {
                $('#loginFormMessage').text('Please fill in your email.');
                    
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            } else {
                var auth = firebase.auth();
                var emailAddress = this.state.email

                auth.sendPasswordResetEmail(emailAddress).then(function() {
                    $('#loginFormMessage').text('Your password has been reset. Please check your email.');
                }).catch(function(error) {
                    $('#loginFormMessage').text('There was an error reseting your password. Please try again.');
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                });
            }
        } else {
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });               
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
              }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
        }
    }

    render() {
        const { isLoggedIn } = this.state;

        if (isLoggedIn) {
            $(".float-close-pro").click();
            this.props.history.push('/dashboard');
        }

        return (
            <Consumer>
                {value => {
                    const { dispatch } = value;

    return (
        <div className="App">
            <HomeNavbar />
<div id="page-title-pro">
    <div class="container">
        <div class="row">
            <h1>Questions? Look here.</h1>
            <h6>Can’t find an answer? Email us at <a href="mailto:thabo@openbeacon.biz">thabo@openbeacon.biz</a></h6>
        </div>
    </div>
</div>
<div class="container">
<div id="content-pro">
    <div class="row">
        <div class="col-md">
            <div class="jumbotron jumbotron-fluid jumbotron-pro">
                <div class="container">
                    <span class="icon-User"></span>
                    <h6 class="light-weight-heading">Subscriber</h6>
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="jumbotron jumbotron-fluid jumbotron-pro">
                <div class="container">
                    <span class="icon-TV"></span>
                    <h6 class="light-weight-heading">Creator</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <h6 class="light-weight-heading">What is RobberBaron?</h6>
            <p>RobberBaron is a service where content creators can create and get paid for premium content by their loyal subscribers at better rates. Your subscribers can view your content on your own site with our plugin. YouTube, Vimeo, DailyMotion and Twitch are supported.</p>
            <hr/>
            <h6 class="light-weight-heading">Membership</h6>
            <p>All users can sign up for free. Creators also post their content for free. To view content though, a monthly fee is paid.</p>
            <hr/>
            <h6 class="light-weight-heading">Streaming Devices</h6>
            <p>RobberBaron is best view on a computer or tabvar.</p>
        </div>
        <div class="col-md">
            <h6 class="light-weight-heading">Plugin</h6>
            <p>There is a RobberBaron plugin availaible at <a href="https://wordpress.org/plugins/robberbaron-tv/" target="_blank">WordPress.org</a>. The role of the plugin is to direct traffic from your personal website to your RobberBaron account so that your followers or anyone who is interested in your can discover your premium content.</p>
            <hr/>
            <h6 class="light-weight-heading">Premium Content</h6>
            <p>Creating your premium content for RobberBaron is easy - simply upload a video to YouTube, Twitch, DailyMotion or Vimeo and make it unlisted. Paste your video's link when creating a RobberBaron rob (video) and you will get paid monthly on your number of subscribers and your content's views.</p>
            <hr/>
            <h6 class="light-weight-heading">Get Started</h6>
            <p>Just click the "Start Now" link to begin your RobberBaron journey.</p>
        </div>
    </div>
    <div class="clearfix"></div>
    </div>
</div>

<footer id="footer-pro">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="copyright-text-pro">&copy; Copyright 2021 <a href="https://getspreebie.com/" target="_blank">Spreebie, Inc</a>. All Rights Reserved</div>
            </div>
            <div class="col-md">
                <ul class="social-icons-pro">
                    <li class="facebook-color"><a href="https://web.facebook.com/spreebieapp/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li class="twitter-color"><a href="https://twitter.com/thabodavidklass?lang=en" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li class="youtube-color"><a href="https://www.youtube.com/channel/UCv8WK-BgICp2Z4NaSuWJ7TA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li class="vimeo-color"><a href="https://vimeo.com/user52673013" target="_blank"><i class="fab fa-vimeo-v"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<a href="#0" id="pro-scroll-top"><i class="fas fa-chevron-up"></i></a>



<div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true">
                                <button type="button" class="close float-close-pro" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header-pro">
                                            <h2>Welcome Back</h2>
                                            <h6 id="loginFormMessage">Sign in to your account to continue using RobberBaron</h6>
                                        </div>
                                        <div class="modal-body-pro social-login-modal-body-pro">
                                            <div class="registration-social-login-container">
                                                <form onSubmit={this.handleSubmit.bind(this, dispatch)}>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="email" placeholder="Username" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" id="password" placeholder="Password" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-green-pro btn-display-block" id="signInButton">Sign In</button>
                                                    </div>
                                                    <div class="container-fluid">
                                                        <div class="row no-gutters">
                                                        <div class="col checkbox-remember-pro"><input type="checkbox" id="checkbox-remember"/><label for="checkbox-remember" class="col-form-label">Remember me</label></div>
                                                        <div class="col forgot-your-password"><a href="#!" onClick={this.handleClick} type="submit" id="forgotPassword">Forgot your password?</a></div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="registration-social-login-or"></div>
                                            </div>
                                            <div class="registration-social-login-options">
                                                <br />
                                                <h6>Watch premium content from your favorite creators</h6>
                                                <br />
                                                <img src="assets/images/logo_big.png" />
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <NavLink to="/signup-step1" class="not-a-member-pro" id="goToSignUpLink" onClick={this.handleClick.bind(this, dispatch)} type="submit">Not a member? Join Today!</NavLink>
                                    </div>
                                </div>
                            </div>
</div>
)
}}
</Consumer>
)
    }
}

export default withRouter(FAQs);