import React, { Component } from 'react';
import axios from 'axios';

const Context = React.createContext();

const reducer = (state, action) => {
    switch(action.type) {
        case 'USER_STATE':
            localStorage.setItem('robberbaron', JSON.stringify({
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                userID: action.payload.userID,
                fullName: action.payload.fullName,
                searchText: action.payload.searchText,
                ownerID: action.payload.ownerID,
                robID: action.payload.robID,
                justLoggedOut: action.payload.justLoggedOut
            }));

            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                userID: action.payload.userID,
                fullName: action.payload.fullName,
                searchText: action.payload.searchText,
                ownerID: action.payload.ownerID,
                robID: action.payload.robID,
                justLoggedOut: action.payload.justLoggedOut
            };
        default:
            return state;
    }
}

export class Provider extends Component {
    state = {
        isLoggedIn: false,
        userID: '',
        fullName: '',
        searchText: '',
        ownerID: '',
        robID: '',
        justLoggedOut: false,
        dispatch: action => this.setState(state => reducer(state, action))
    }

    componentDidMount() {
        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));
        this.setState(persistedState);
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                { this.props.children }
            </Context.Provider>
        )
    }
}

export const Consumer = Context.Consumer;