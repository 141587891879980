import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import HomeNavbar from '../layout/HomeNavBar';
import firebase from '../../firebase';
import { Consumer } from '../../context';
import $ from 'jquery';
require('firebase/auth');
require('jquery-ui-bundle');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class Index extends React.Component {
    state = {
        email: '',
        password: '',
        isLoggedIn: false,
        userID: '',
        fullName: '',
        isLoggedInFromRedirect: true
    }

    componentWillMount() {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/script.js");

        var isLoggedInFromRedirect = true;

        if ((this.props.location.appProps)) {
            isLoggedInFromRedirect = this.props.location.appProps.isLoggedIn;
        }

        this.setState({
            ...this.state,
            isLoggedIn: false,
            userID: '',
            fullName: '',
            isLoggedInFromRedirect
        });
    }

    handleSubmit = (dispatch, e) => {
        e.preventDefault()

        console.log('Submit button clicked!');
        $('#signInButton').text('Please Wait');
        $('#signInButton').prop('disabled', true);

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .then((user) => {
                var user = firebase.auth().currentUser;  
                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });             
                    } else {
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            }, function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log('Error: ' + error.code + ' ', errorMessage);

                if (errorCode === "auth/user-not-found") {
                    $('#loginFormMessage').text('The email you entered is wrong. Please try again.');
                } else if (errorCode === "auth/wrong-password") {
                    $('#loginFormMessage').text('The password is wrong. Please try again.');
                } else {
                    $('#loginFormMessage').text('There was an error logging. Please try again.');
                }

                $('#signInButton').text('Sign In');
                $('#signInButton').prop('disabled', false);
                
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            });
    }

    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({
            [e.target.id]: e.target.value
        })

        $('#loginFormMessage').css('color', '#000000');
        $('#loginFormMessage').text('Sign in to your account to continue using RobberBaron');
    }

    handleClick = (dispatch, e) => {
        e.preventDefault()

        if (e.target.id === 'facebookSignIn') { 
            var provider = new firebase.auth.FacebookAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                console.log(user);

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data();

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });               
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
            }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
        } else if (e.target.id === 'goToSignUpLink') {
            $(".float-close-pro").click();
            this.props.history.push('/signup-step1');
        } else if (e.target.id === 'forgotPassword') {
            if (this.state.email === '') {
                $('#loginFormMessage').text('Please fill in your email.');
                    
                $('#loginFormMessage').css('color', '#ff0000')
                $("#LoginModal").effect("shake");
            } else {
                var auth = firebase.auth();
                var emailAddress = this.state.email

                auth.sendPasswordResetEmail(emailAddress).then(function() {
                    $('#loginFormMessage').text('Your password has been reset. Please check your email.');
                }).catch(function(error) {
                    $('#loginFormMessage').text('There was an error reseting your password. Please try again.');
                    
                    $('#loginFormMessage').css('color', '#ff0000')
                    $("#LoginModal").effect("shake");
                });
            }
        } else {
            var provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().signInWithPopup(provider).then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;

                var userRef = firebase.firestore().collection('users').doc(user.uid)

                userRef.get().then((user) => {
                    if (user.exists) {
                        const { fullName, userID } = user.data()
                        console.log('The full name is: ', fullName);
                        console.log('The userID is: ', userID);

                        dispatch({
                            type: 'USER_STATE',
                            payload: {
                                isLoggedIn: true,
                                userID,
                                fullName,
                                justLoggedOut: false
                            }
                        });

                        this.setState({
                            ...this.state,
                            isLoggedIn: true,
                            userID,
                            fullName
                        });               
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
                // ...
              }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
        }
    }

    render() {
        console.log("Inside render()")
        // After the user is logged in
        const { isLoggedIn } = this.state;

        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

        if (isLoggedIn) {
            $(".float-close-pro").click();
            this.props.history.push('/dashboard');
        } else if (persistedState != null && persistedState.userID !== undefined && persistedState.userID !=='' && persistedState.isLoggedIn && this.state.isLoggedInFromRedirect) {
            this.props.history.push('/dashboard');
        } else {
            // Do nothing for now
        }

        return (
            <Consumer>
                {value => {
                    const { dispatch } = value;
                    return(
                        <div className="App">
                            <HomeNavbar />
                            <div class="flexslider progression-studios-slider">
                                <ul class="slides">
                                    <li class="progression_studios_animate_in">
                                        <div class="progression-studios-slider-image-background" style={{ backgroundImage: 'url(assets/graphics/slider-01.png)'}}>
                                            <div class="progression-studios-slider-display-table">
                                                <div class="progression-studios-slider-vertical-align">
                                                    <div class="container">
                                                        <div class="progression-studios-slider-caption-width">
                                                            <div class="progression-studios-slider-caption-align">
                                                                <h2>Welcome to RobberBaron<span style= {{ color: '000000' }}></span></h2>
                                                                <h6>Create and get paid for premium content by your loyal subscribers at better rates.</h6>
                                                                <NavLink to="/signup-step1" class="btn btn-green-pro btn-slider-pro btn-shadow-pro" role="button">Sign Up Now</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="progression-studios-slider-mobile-background-cover"></div>
                                        </div>
                                    </li>
                                    <li class="progression_studios_animate_right">
                                        <div class="progression-studios-slider-image-background" style={{ backgroundImage: 'url(assets/graphics/slider-03.png)', backgroundColor: 111015 }}>
                                            <div class="progression-studios-slider-display-table">
                                                <div class="progression-studios-slider-vertical-align">
                                                    <div class="container">
                                                        <div class="progression-studios-slider-caption-width">
                                                            <div class="progression-studios-slider-caption-align">
                                                                <h2 class="light-fonts-pro">Decentralised Discovery</h2>
                                                                <h6 class="light-fonts-pro">Your current followers can easily discover your RobberBaron content through <a href="https://wordpress.org/plugins/robberbaron-tv/" target="_blank">our WordPress plugin</a>.</h6>
                                                                <NavLink to="/signup-step1" class="btn btn-green-pro btn-slider-pro" role="button">View The Video Library</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="progression_studios_animate_left">
                                        <div class="progression-studios-slider-image-background" style={{ backgroundImage: 'url(assets/graphics/slider-02.png)' }}>
                                            <div class="progression-studios-slider-display-table">
                                                <div class="progression-studios-slider-vertical-align">
                                                    <div class="container">
                                                        <div class="progression-studios-slider-caption-width">
                                                            <div class="progression-studios-slider-caption-align">
                                                                <h2>Major Video Platforms Supported</h2>
                                                                <h6>YouTube, Vimeo, DailyMotion and Twitch are supported.</h6>
                                                                <NavLink to="/signup-step1" class="btn btn-green-pro btn-slider-pro btn-shadow-pro" role="button">Sign Up Now</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="progression-studios-slider-mobile-background-cover"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="content-pro">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md my-auto">
                                            <img src="assets/graphics/get-payed.png" class="img-fluid" alt="Watch in Any Devices"/>
                                        </div>
                                        <div class="col-md my-auto">
                                            <h2 class="short-border-bottom">Get Paid For Premium Content</h2>
                                            <p>Already have a big following on the major video platforms? Get paid by your existing subscribers by creating premium content, making it unlisted and posting the links on RobberBaron. This is a great way to talk about what you really want and get paid for it without worrying about getting demonetized or deplatformed.</p>
                                            <div style={{ height: 15 }}></div>
                                            <p><NavLink to="/signup-step1" class="btn btn-green-pro" role="button">Learn More</NavLink></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md my-auto">
                                            <h2 class="short-border-bottom">Decentralised Discovery</h2>
                                            <p>Your RobberBaron content will get discovered by anyone who is interested in you online. If you have a WordPress blog or site, <a href="https://wordpress.org/plugins/robberbaron-tv/" target="_blank">our RobberBaron plugin</a> will direct paying subscribers to your premium content and give you even more exposure. The avenues are broader and less centralised for the content you passionately create.</p>
                                            <div style={{ height: 15 }}></div>
                                            <p><a href="https://wordpress.org/plugins/robberbaron-tv/" target="_blank" class="btn btn-green-pro" role="button">Download Plugin</a></p>
                                        </div>
                                        <div class="col-md my-auto">
                                            <img src="assets/graphics/blogs.png" class="img-fluid" alt="Make Your Own Playlist"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md my-auto">
                                            <img src="assets/graphics/more-money.png" class="img-fluid" alt="Watch in Ultra HD"/>
                                        </div>
                                        <div class="col-md my-auto">
                                            <h2 class="short-border-bottom">MORE Money For YOUR Content</h2>
                                            <p>Our fees are a LOT lower and less complex - you get more per dollar than anywhere else. Our goal is to make the creator central to everything. The main focus of RobberBaron is your premium content. What we care about, above everything else, is to alleviate the worry of monetisation so that you can focus and what you’re great at - CREATING AMAZING CONTENT.</p>
                                            <div style={{ height: 15 }}></div>
                                            <p><NavLink to="/signup-step1" class="btn btn-green-pro" role="button">Sign Up Now</NavLink></p>
                                        </div>
                                    </div>
                                    <div style={{ height: 35 }}></div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="progression-pricing-section-background">
                                    <div class="container">
                                        <div class="row">
                                            <div class="mx-auto">
                                                <div style={{ height: 70 }}></div>
                                                <h2 class="short-border-bottom">Our Plans &amp; Pricing</h2>
                                            </div>
                                        </div>
                                        <div style={{height: 25}}></div>
                                        <div class="row">
                                            <div class="col-md">
                                                <ul class="checkmark-list-pro">
                                                    <li>1 month unlimited access!</li>
                                                    <li>Premium content from your favorite creator and influencer.</li>
                                                </ul>
                                            </div>
                                            <div class="col-md">
                                                <ul class="checkmark-list-pro">
                                                    <li>Stream on your phone, laptop, tabvar or TV.</li>
                                                    <li><a href="https://wordpress.org/plugins/robberbaron-tv/" target="_blank">Plugin</a> for your personal WordPress blog.</li>
                                                </ul>
                                            </div>
                                            <div class="col-md">
                                                <ul class="checkmark-list-pro">
                                                    <li>All countries with PayPal supported.</li>
                                                    <li>No pornographic content.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="pricing-table-pro">
                                            <div class="row">
                                                <div class="col-md">
                                                    <div class="pricing-table-col">
                                                        <h6>FREE</h6>
                                                        <h2>Free</h2>
                                                        <ul>
                                                            <li>Post Videos</li>
                                                        </ul>
                                                        <p><NavLink to="/signup-step2" class="btn" role="button">Choose Plan</NavLink></p>
                                                    </div>
                                                </div>
                                                <div class="col-md">
                                                    <div class="pricing-table-col pricing-table-col-shadow-pro">
                                                        <h6>STARTER</h6>
                                                        <h2><sup>$</sup>5<span> / month</span></h2>
                                                        <ul>
                                                            <li>Post Videos</li>
                                                            <li>Works on any Device</li>
                                                            <li>Watch General Content</li>
                                                        </ul>
                                                        <p><NavLink to="/signup-step2" class="btn btn-green-pro" role="button">Choose Plan</NavLink></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <footer id="footer-pro">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md">
                                            <div class="copyright-text-pro">&copy; Copyright 2021 <a href="https://getspreebie.com/" target="_blank">Spreebie, Inc</a>. All Rights Reserved</div>
                                        </div>
                                        <div class="col-md">
                                            <ul class="social-icons-pro">
                                                <li class="facebook-color"><a href="https://web.facebook.com/spreebieapp/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                                <li class="twitter-color"><a href="https://twitter.com/thabodavidklass?lang=en" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                                <li class="youtube-color"><a href="https://www.youtube.com/channel/UCv8WK-BgICp2Z4NaSuWJ7TA" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                                <li class="vimeo-color"><a href="https://vimeo.com/user52673013" target="_blank"><i class="fab fa-vimeo-v"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                            <a href="#0" id="pro-scroll-top"><i class="fas fa-chevron-up"></i></a>
                            <div class="modal fade" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true">
                                <button type="button" class="close float-close-pro" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header-pro">
                                            <h2>Welcome Back</h2>
                                            <h6 id="loginFormMessage">Sign in to your account to continue using RobberBaron</h6>
                                        </div>
                                        <div class="modal-body-pro social-login-modal-body-pro">
                                            <div class="registration-social-login-container">
                                                <form onSubmit={this.handleSubmit.bind(this, dispatch)}>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="email" placeholder="Email" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <input type="password" class="form-control" id="password" placeholder="Password" onChange={this.handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-green-pro btn-display-block" id="signInButton">Sign In</button>
                                                    </div>
                                                    <div class="container-fluid">
                                                        <div class="row no-gutters">
                                                        <div class="col checkbox-remember-pro"><input type="checkbox" id="checkbox-remember"/><label for="checkbox-remember" class="col-form-label">Remember me</label></div>
                                                        <div class="col forgot-your-password"><a href="#!" onClick={this.handleClick} type="submit" id="forgotPassword">Forgot your password?</a></div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="registration-social-login-or"></div>
                                            </div>
                                            <div class="registration-social-login-options">
                                                <br />
                                                <h6>Watch premium content from your favorite creators</h6>
                                                <br />
                                                <img src="assets/images/logo_big.png" />
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <NavLink to="/signup-step1" class="not-a-member-pro" id="goToSignUpLink" onClick={this.handleClick.bind(this, dispatch)} type="submit">Not a member? Join Today!</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Consumer>
        )
    }
}

export default withRouter(Index);