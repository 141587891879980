import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import firebase from '../../firebase';
import ReactPlayer from "react-player";
import Rob from '../classes/Rob';
import Notification from '../classes/Notification';
require('firebase/auth');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class Dashboard extends React.Component  {
    state = {
        currentPage: 1,
        robsPerPage: 12,
        currentRobs: [],
        pageNumbers: [],
        currentRobsLoaded: false,
        userID: '',
        fullName: '',
        dataLoaded: false,
        searchText: '',
        profilePictureFileName: '',
        profilePictureFileNameLoaded: false,
        latestRobURL: '',
        mostPopularRobGenre: '',
        robs: [],
        subs: [],
        subsLoaded: false,
        notifications: [],
        notificationsLoaded: false,
        plan: "",
        getPayPalAuthToken: false,
        payPalAccessToken: '',
        getPayPalSubscriptionStatusCalled: false,
        subscriptionID: '',
        premiumSubscriptionStatus: '',
        premiumSubscriptionNextBillingTime: '',
        isStripeSubscriptionActive: false
    };

    componentDidMount () {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/jquery-asRange.min.js");
        appendScript("assets/js/circle-progress.min.js");
        appendScript("assets/js/afterglow.min.js");
        appendScript("assets/js/script.js");
        appendScript("assets/js/script-dashboard.js");
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: '',
                justLoggedOut: true
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })

        if (e.target.id === 'searchText') {            
            this.props.history.push('/dashboard-search/' + e.target.value);
        }
    }

    handleClick = (e) => {
        e.preventDefault();

        this.setState({
            ...this.state,
            currentPage: Number(e.target.id),
            currentRobsLoaded: false
        });
    }

    getUserData = () => {
        if (this.state.userID) {
            var userRef = firebase.firestore().collection('users').doc(this.state.userID)

            userRef.get().then((user) => {
                if (user.exists) {
                    const { profilePictureFileName, plan, subscriptionID } = user.data()

                    this.setState({
                        ...this.state,
                        profilePictureFileNameLoaded: true,
                        profilePictureFileName,
                        plan,
                        subscriptionID
                    });
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    }

    getMostViewedRobs = (userID, persistedState) => {
        var robRef = firebase.firestore().collection('robMeta')

        robRef.get()
            .then((snapshot) => {
                var robs = [];
                var latestRobURL = '';
                var mostPopularRobGenre = '';

                snapshot.forEach((doc) => {
                    const { createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views } = doc.data();
                    var robObject = new Rob(robID, createdAt, description, genre, robID, screenshotURL, tags, title, updateAt, videoURL, ownerID, views);
                    robs.push(robObject);

                    robs.sort(this.compare);

                    if (robs.length > 0) {
                        latestRobURL = robs[0].videoURL;
                        mostPopularRobGenre = robs[0].genre
                    }
                });

                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    userID,
                    fullName: persistedState.fullName,
                    robs,
                    latestRobURL,
                    mostPopularRobGenre
                });

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    compare = (a, b) => {
        if (a.views < b.views) return 1;
        if (b.views < a.views) return -1;
      
        return 0;
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                })

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    console.log("The count is: ", count)

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }

    updatePlan = (userID, plan) => {
        const userRef = firebase.firestore().collection('users').doc(userID)

        userRef
            .update({
                plan,
                updateAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                console.log('Update compvare.')
            })
    }

    getStripeSubscriptionStatus = (userID) => {
        let subscriptionsRef = firebase.firestore().collection('users').doc(userID).collection('subscriptions');
            
        subscriptionsRef.get()
            .then((snapshot) => {
                var notifications = [];
                console.log('Setup: ', snapshot);

                snapshot.forEach((doc) => {
                    console.log('Subscriptions: ', doc.data());
                    let { status, current_period_end } = doc.data();
                    console.log('Status: ', status);

                    if (status === 'active') {
                        this.setState({
                            ...this,
                            isStripeSubscriptionActive: true
                        });
                    }
                });
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    render() {
        var playbackContent;

        if (!this.state.isStripeSubscriptionActive) {
            playbackContent = <div class="progression-studios-slider-dashboard-image-background" style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/robberbarontv.appspot.com/o/playback-disabled.png?alt=media&token=f4147a08-44a1-43e0-9c20-d5b75899de0c)' }}></div>;
        } else if(this.state.plan === "Starter") {
            playbackContent = <ReactPlayer width='1120px' height='560px' controls={ true } url={this.state.latestRobURL} />
        } else {
            playbackContent = <div></div>
        }
                   
        return (
            <Consumer>
                {value => {
                    const { isLoggedIn, userID, dispatch } = value;

                    console.log("The user brr: ", userID);

                    var persistedState = JSON.parse(localStorage.getItem('robberbaron'));

                    if (!persistedState || !persistedState.userID || persistedState.userID === '') {
                        this.props.history.push('/');
                    } else {
                        if (!this.state.profilePictureFileNameLoaded) {
                            this.getUserData();
                        }

                        if (userID === persistedState.userID) {
                            if (!this.state.dataLoaded) {
                                this.getStripeSubscriptionStatus(userID);
                                this.getMostViewedRobs(userID, persistedState);
                            }

                            if (!this.state.subsLoaded) {    
                                this.getSubs(userID);
                            } else {
                                if (!this.state.notificationsLoaded) {
                                    this.getNotifications(this.state.subs);
                                }
                            }
                        }

                        if (this.state.robs.length > 0 && !this.state.currentRobsLoaded) {
                            const { robs, currentPage, robsPerPage } = this.state;

                            // Logic for displaying todos
                            const indexOfLastRob = currentPage * robsPerPage;
                            const indexOfFirstRob = indexOfLastRob - robsPerPage;
                            const currentRobs = robs.slice(indexOfFirstRob, indexOfLastRob);

                            const pageNumbers = [];

                            for (var i = 1; i <= Math.ceil(this.state.robs.length / this.state.robsPerPage); i++) {
                                pageNumbers.push(i);
                            }

                            var latestRobURL = currentRobs[0].videoURL;
                            var mostPopularRobGenre = currentRobs[0].genre;

                            this.setState({
                                ...this.state,
                                currentRobs,
                                currentRobsLoaded: true,
                                pageNumbers,
                                latestRobURL,
                                mostPopularRobGenre
                            });
                        }
                    }

                    return (
                        <div className="App">
                            <div id="sidebar-bg">
                                <header id="videohead-pro" class="sticky-header">
                                    <div id="video-logo-background"><Link to="/dashboard"><img src="assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                                    <div id="video-search-header">
                                        <div id="search-icon-more"></div>
                                        <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                                    </div>
                                    
                                    <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                                    
                                    
                                    <div id="header-user-profile">
                                        <div id="header-user-profile-click" class="noselect">
                                            <img src={this.state.profilePictureFileName} />
                                                <div id="header-username">{this.state.fullName}</div><i class="fas fa-angle-down"></i>
                                        </div>
                                        <div id="header-user-profile-menu">
                                            <ul>
                                                <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                                                <li><NavLink to="/dashboard-mine"><span class="icon-Profile"></span>Mine</NavLink></li>
                                                <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                                                <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                                                <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div id="header-user-notification">
                                        <div id="header-user-notification-click" class="noselect">
                                            <i class="far fa-bell"></i>
                                            <span class="user-notification-count">3</span>
                                        </div>
                                        <div id="header-user-notification-menu">
                                            <h3>Notifications</h3>
                                            <div id="header-notification-menu-padding">
                                                    <ul id="header-user-notification-list">
                                                        { this.state.notifications.map((notification) => {
                                                            return(
                                                                <li key={notification.key}><NavLink to={{ pathname: '/dashboard-movie-profile/' + notification.robID, robProps: {robID: notification.robID, ownerID: notification.robOwnerID }}}><img src="/assets/graphics/notification-flat.png" alt="Profile"/>{ notification.robTitle } <div class="header-user-notify-time">{ notification.creationAt.toDate().toString() }</div></NavLink></li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                    </div>

                                    <div id="header-user-create">
                                            <div id="header-user-create-click" class="noselect">
                                                <NavLink to="/dashboard-video-upload">
                                                    <img src="assets/images/create.png" alt="Create"/>
                                                </NavLink>
                                            </div>
                                        </div>
                                    
                                    
                                    
                                    <div class="clearfix"></div>
                                    
                                    <nav id="mobile-navigation-pro">
                                    
                                        <ul id="mobile-menu-pro">
                                            <li>
                                                <NavLink to="/dashboard">
                                                    <span class="icon-Fire-Flame"></span>
                                                Popular
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashboard-subs">
                                                    <span class="icon-RSS"></span>
                                                    New Releases
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashboard-new-releases">
                                                    <span class="icon-Paper-Plane"></span>
                                                    Subscriptions
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/dashboard-mine">
                                                    <span class="icon-Profile"></span>
                                                    Mine
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i class="far fa-bell"></i>
                                                    <span class="user-notification-count">3</span>
                                                Notifications
                                                </a>
                                            </li>
                                            </ul>
                                        <div class="clearfix"></div>
                                        <div id="search-mobile-nav-pro">
                                            <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                                        </div>
                                    </nav>
                                </header>
                                <nav id="sidebar-nav">
                                    <ul id="vertical-sidebar-nav" class="sf-menu">
                                        <li class="normal-item-pro">  
                                            <NavLink to="/dashboard">
                                                <span class="icon-Fire-Flame"></span>
                                                Popular
                                            </NavLink>
                                        </li>
                                        <li class="normal-item-pro">
                                            <NavLink to="/dashboard-subs">
                                                <span class="icon-RSS"></span>
                                                Subs
                                            </NavLink>
                                        </li>
                                        <li class="normal-item-pro">
                                            <NavLink to="/dashboard-new-releases">
                                                <span class="icon-Paper-Plane"></span>
                                                New Releases
                                            </NavLink>
                                        </li>
                                        <li class="normal-item-pro">
                                            <NavLink to="/dashboard-mine">
                                                <span class="icon-Profile"></span>
                                                Mine
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <div class="clearfix"></div>
                                </nav>
                    
                        <main id="col-main">
                            
                            
                            
                            <div class="flexslider progression-studios-dashboard-slider progression-studios-full-height-slider">
                                {playbackContent}
                            </div>
                
                            <ul class="dashboard-genres-pro">
                                <li class={this.state.mostPopularRobGenre === 'Film' ? 'active' : '' }>
                                    <img src="assets/images/categories/film.png" alt="Film"/>
                                    <h6>Film</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Cars' ? 'active' : '' }>
                                    <img src="assets/images/categories/cars.png" alt="Cars"/>
                                    <h6>Cars</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Music' ? 'active' : '' }>
                                    <img src="assets/images/categories/music.png" alt="Music"/>
                                    <h6>Music</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Animals' ? 'active' : '' }>
                                    <img src="assets/images/categories/animals.png" alt="Animals"/>
                                    <h6>Animals</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Sports' ? 'active' : '' }>
                                    <img src="assets/images/categories/sports.png" alt="Sports"/>
                                    <h6>Sports</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Travel' ? 'active' : '' }>
                                    <img src="assets/images/categories/travel.png" alt="Travel"/>
                                    <h6>Travel</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Gaming' ? 'active' : '' }>
                                    <img src="assets/images/categories/gaming.png" alt="Gaming"/>
                                    <h6>Gaming</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Comedy' ? 'active' : '' }>
                                    <img src="assets/images/categories/comedy.png" alt="Comedy"/>
                                    <h6>Comedy</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Politics' ? 'active' : '' }>
                                    <img src="assets/images/categories/politics.png" alt="Politics"/>
                                    <h6>Politics</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Education' ? 'active' : '' }>
                                    <img src="assets/images/categories/education.png" alt="Education"/>
                                    <h6>Education</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Science' ? 'active' : '' }>
                                    <img src="assets/images/categories/science.png" alt="Science"/>
                                    <h6>Science</h6>
                                </li>
                                <li class={this.state.mostPopularRobGenre === 'Other' ? 'active' : '' }>
                                    <img src="assets/images/categories/other.png" alt="Other"/>
                                    <h6>Other</h6>
                                </li>
                            </ul>
                            
                            <div class="clearfix"></div>
                            
                            <div class="dashboard-container">
                                
                                <h4 class="heading-extra-margin-bottom">Popular</h4>
                                <div class="row">
                                { this.state.currentRobs.map((rob) => {
                                        console.log("Inside complex loop.")
                                        return(
                                    <div class="col-12 col-md-6 col-lg-4 col-xl-3" key={rob.key}>
                                        <div class="item-listing-container-skrn">
                                        <NavLink to={{ pathname: '/dashboard-movie-profile/' + rob.robID, robProps: {robID: rob.robID, ownerID: rob.ownerID }}}><img src={ rob.screenshotURL === '' ? "https://via.placeholder.com/450x620" : rob.screenshotURL } alt="Listing"/></NavLink>
                                            <div class="item-listing-text-skrn">
                                                <div class="item-listing-text-skrn-vertical-align"><h6><NavLink to={{ pathname: '/dashboard-movie-profile/' + rob.robID, robProps: {robID: rob.robID, ownerID: rob.ownerID }}}>{rob.title}</NavLink></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        )
                                    })
                                }
                                    
                                </div>
                                
                                
                                <ul class="page-numbers">
                                {this.state.pageNumbers.map(number => {
                                        if (this.state.currentPage === number) {
                                            return (
                                                <li key={number}>
                                                    <span id={number} class="page-numbers current" onClick={this.handleClick}>
                                                        {number}
                                                    </span>
                                                </li>
                                            )
                                        } else {
                                            return (
                                                <li key={number}>
                                                    <a id={number} class="page-numbers" href="#!" onClick={this.handleClick}>
                                                        {number}
                                                    </a>
                                                </li>
                                            );
                                        }
                                    })
                                    }
                                </ul>
                                
                                        
                            </div>
                        </main>
                        
                        
                        </div>
                        </div>
                    )
                }
            }
            </Consumer>
        )
    }
}

export default withRouter(Dashboard);