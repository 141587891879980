import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Consumer } from '../../context';
import firebase from '../../firebase';
require('firebase/auth');

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = false;
    document.body.appendChild(script);
}

class DashboardProfile extends React.Component  {
    state = {
        userID: '',
        fullName: '',
        dataLoaded: false,
        searchText: '',
        profilePictureFileName: '',
        profilePictureFileNameLoaded: false
    }

    componentDidMount () {
        appendScript("assets/js/libs/jquery-3.3.1.min.js");
        appendScript("assets/js/libs/popper.min.js");
        appendScript("assets/js/libs/bootstrap.min.js");
        appendScript("assets/js/navigation.js");
        appendScript("assets/js/jquery.flexslider-min.js");
        appendScript("assets/js/jquery-asRange.min.js");
        appendScript("assets/js/circle-progress.min.js");
        appendScript("assets/js/afterglow.min.js");
        appendScript("assets/js/script.js");
        appendScript("assets/js/script-dashboard.js");
    }

    logOut = (e) => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            var loggedOutState = {
                isLoggedIn: false,
                userID: '',
                fullName: ''
            };
    
            localStorage.setItem('robberbaron', JSON.stringify(loggedOutState));
        }).catch(function(error) {
            // An error happened.
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });

        if (e.target.id === 'searchText') {            
            this.props.history.push('/dashboard-search/' + e.target.value);
        }
    }

    getUserData = () => {
        if (this.state.userID) {
            var userRef = firebase.firestore().collection('users').doc(this.state.userID)

            userRef.get().then((user) => {
                if (user.exists) {
                    const { profilePictureFileName } = user.data()

                    this.setState({
                        ...this.state,
                        profilePictureFileNameLoaded: true,
                        profilePictureFileName
                    });
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        }
    }

    compareNotifications = (a, b) => {
        if (a.creationAt < b.creationAt) return 1;
        if (b.creationAt < a.creationAt) return -1;
      
        return 0;
    }
    
    getSubs(subscriber) {
        var subRef = firebase.firestore().collection('subscriptions').doc(subscriber).collection('data')

        subRef.get()
            .then((snapshot) => {
                var subs = [];

                snapshot.forEach((doc) => {
                    const { creator } = doc.data();
                    subs.push(creator);
                });

                var subsLoaded = true;

                this.setState({
                    ...this.state,
                    subs,
                    subsLoaded
                });
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    }

    getNotifications = (subs) => {
        var count = 0;

        subs.forEach((creator) => {
            var notificationRef = firebase.firestore().collection('notifications').doc(creator).collection('data');
            
            notificationRef.get()
                .then((snapshot) => {
                    var notifications = [];

                    snapshot.forEach((doc) => {
                        const { createdAt, robID, robOwnerID, robTitle, updatedAt } = doc.data();
                        var notificationObject = new Notification(robID, createdAt, robID, robOwnerID, robTitle, updatedAt);
                        
                        notifications.push(notificationObject);

                        notifications.sort(this.compareNotifications)
                    });

                    count++;

                    if (count === subs.length) {
                        this.setState({
                            ...this.state,
                            notifications,
                            notificationsLoaded: true
                        })
                    }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        });
    }
    
    render() {
        return (
            <Consumer>
                    {value => {
                        const { isLoggedIn, userID, dispatch } = value;

                        if (!this.state.profilePictureFileNameLoaded) {
                            this.getUserData();
                        }
    
                        var persistedState = JSON.parse(localStorage.getItem('robberbaron'));
    
                        if (userID === persistedState.userID) {
                            if (!this.state.dataLoaded) {
                                this.setState({
                                    ...this.state,
                                    dataLoaded: true,
                                    userID,
                                    fullName: persistedState.fullName
                                })
                            }   
                        }
        return (
            <div className="App">
                <div id="sidebar-bg">
			
            <header id="videohead-pro" class="sticky-header">
                <div id="video-logo-background"><Link to="/dashboard"><img src="assets/images/logo-video-layout.png" alt="Logo"/></Link></div>
                  
                  <div id="video-search-header">
                      <div id="search-icon-more"></div>
                      <input id="searchText" type="text" placeholder="Search for a video or user's name" aria-label="Search" onChange={this.handleChange} />
                  </div>
                  
                  <div id="mobile-bars-icon-pro" class="noselect"><i class="fas fa-bars"></i></div>
                  
                  
                  <div id="header-user-profile">
                      <div id="header-user-profile-click" class="noselect">
                          <img src={this.state.profilePictureFileName} />
                            <div id="header-username">{this.state.fullName}</div><i class="fas fa-angle-down"></i>
                      </div>
                      <div id="header-user-profile-menu">
                          <ul>
                              <li><NavLink to={"/dashboard-profile/" + this.state.userID}><span class="icon-User"></span>My Profile</NavLink></li>
                              <li><NavLink to="/dashboard-favorites"><span class="icon-Profile"></span>My Favorites</NavLink></li>
                              <li><NavLink to="/dashboard-account"><span class="icon-Gears"></span>Account Details</NavLink></li>
                              <li><a href="https://robberbaron.s3.amazonaws.com/RobberBaron_Quick_Start_Guide.zip"><span class="icon-Life-Safer"></span>Help/Support</a></li>
                              <li><NavLink to={{ pathname: '/', appProps: { isLoggedIn: false }}} onClick={this.logOut} type="submit"><span class="icon-Power-3"></span>Log Out</NavLink></li>
                          </ul>
                      </div>
                  </div>
                  
                  <div id="header-user-notification">
                      <div id="header-user-notification-click" class="noselect">
                          <i class="far fa-bell"></i>
                          <span class="user-notification-count">3</span>
                      </div>
                      <div id="header-user-notification-menu">
                          <h3>Notifications</h3>
                          <div id="header-notification-menu-padding">
                                  <ul id="header-user-notification-list">
                                      <li><a href="#!"><img src="assets/images/demo/user-profile-2.jpg" alt="Profile"/>Lorem ipsum dolor sit amet, consec tetur adipiscing elit. <div class="header-user-notify-time">21 hours ago</div></a></li>
                                      <li><a href="#!"><img src="assets/images/demo/user-profile-3.jpg" alt="Profile"/>Donec vitae lacus id arcu molestie mollis. <div class="header-user-notify-time">3 days ago</div></a></li>
                                      <li><a href="#!"><img src="assets/images/demo/user-profile-4.jpg" alt="Profile"/>Aenean vitae lectus non purus facilisis imperdiet. <div class="header-user-notify-time">5 days ago</div></a></li>
                                  </ul>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                  </div>

                  <div id="header-user-create">
                        <div id="header-user-create-click" class="noselect">
                            <NavLink to="/dashboard-video-upload">
                                <img src="assets/images/create.png" alt="Create"/>
                            </NavLink>
                        </div>
                    </div>
                  
                  
                  
                  <div class="clearfix"></div>
                  
                  <nav id="mobile-navigation-pro">
                  
                  <ul id="mobile-menu-pro">
                        <li class="current-menu-item">
                            <NavLink to="/dashboard">
                                <span class="icon-Old-TV"></span>
                            Popular
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-subs">
                                <span class="icon-Reel"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Movie-Ticket"></span>
                                Subscriptions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/dashboard-mine">
                                <span class="icon-Clock"></span>
                                Mine
                            </NavLink>
                        </li>
                        <li>
                            <a href="#!">
                                <i class="far fa-bell"></i>
                                <span class="user-notification-count">3</span>
                            Notifications
                            </a>
                        </li>
                        </ul>
                      <div class="clearfix"></div>
                      
                      <div id="search-mobile-nav-pro">
                          <input type="text" placeholder="Search for Movies or TV Series" aria-label="Search"/>
                      </div>
                      
                  </nav>
                  
            </header>
              
              
              
              <nav id="sidebar-nav">
                  <ul id="vertical-sidebar-nav" class="sf-menu">
                  <li class="normal-item-pro">
                        <NavLink to="/dashboard">
                              <span class="icon-Old-TV"></span>
                          Popular
                        </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-subs">
                                <span class="icon-Reel"></span>
                                Subs
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                            <NavLink to="/dashboard-new-releases">
                                <span class="icon-Movie-Ticket"></span>
                                New Releases
                            </NavLink>
                        </li>
                        <li class="normal-item-pro">
                        <NavLink to="/dashboard-mine">
                                <span class="icon-Clock"></span>
                                Mine
                            </NavLink>
                        </li>
      
                  </ul>
                      <div class="clearfix"></div>
              </nav>
              
              
              <div id="content-sidebar-pro">
                  
                  <div id="content-sidebar-info">
                      <img src="https://via.placeholder.com/400x498"  alt="Suzie"/>
                      <div id="profile-sidebar-name">
                          <h5>Suzie Smith</h5>
                          <h6>Manila, Philippines</h6>
                      </div>
                      <div id="profile-sidebar-gradient"></div>
                      <a href="dashboard-account.html" class="edit-profile-sidebar"><i class="fas fa-pencil-alt"></i></a>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h3 class="content-sidebar-sub-header">Watched</h3>
                      <ul id="profile-watched-stats">
                          <li><span>43</span> Movies</li>
                          <li><span>28</span> Series</li>
                          <li><span>07</span> Docs</li>
                      </ul>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h3 class="content-sidebar-sub-header">Favorite Genres</h3>
                      <ul id="profile-favorite-genres">
                          <li>
                              <img src="assets/images/genres/comedy.png" alt="Comedy"/>
                          </li>
                          <li>
                              <img src="assets/images/genres/action.png" alt="Action"/>
                          </li>
                          <li>
                              <img src="assets/images/genres/romance.png" alt="Romance"/>
                          </li>
                          <li>
                              <img src="assets/images/genres/fantasy.png" alt="Fantasy"/>
                          </li>
                      </ul>
                      <div class="clearfix"></div>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h3 class="content-sidebar-sub-header">Friends: 87</h3>
                      <ul id="friends-activity-profiles">
                          <li><a href="#!"><img src="assets/images/demo/user-8.jpg" alt="Friend"/></a></li>
                          <li><a href="#!"><img src="assets/images/demo/user-9.jpg" alt="Friend"/></a></li>
                          <li><a href="#!"><img src="assets/images/demo/user-10.jpg" alt="Friend"/></a></li>
                          <li><a href="#!"><img src="assets/images/demo/user-11.jpg" alt="Friend"/></a></li>
                          <li class="friends-activity-profiles-more"><a href="#!">+83</a></li>
                      </ul>
                      <div class="clearfix"></div>
                  </div>
                  
                  <div class="content-sidebar-section">
                      <h3 class="content-sidebar-sub-header">Friends Activity</h3>
                      <ul id="friends-activity-feed">
                          <li><img src="assets/images/demo/user-15.jpg" alt="Friend"/>Added <a href="#!">Interstellar</a> to her Favorite Playlist</li>
                          <li><img src="assets/images/demo/user-8.jpg" alt="Friend"/>Wants to Watch <a href="#!">The Imitation Game</a></li>
                          <li><img src="assets/images/demo/user-9.jpg" alt="Friend"/>Added <a href="#!">Moonlight</a> to his <a href="#!">Playlist Best Dramas</a></li>
                          <li><img src="assets/images/demo/user-4.jpg" alt="Friend"/>Watched <a href="#!">Transformers: The Last Knight</a></li>
                      </ul>
                      <div class="clearfix"></div>
                  </div>
                  
                  
              </div>
          
              <main id="col-main-with-sidebar">
                  
                  <div class="dashboard-container">
                      
                      <ul class="dashboard-sub-menu">
                          <li><NavLink to={"/dashboard-profile/" + this.state.userID}>Recommended</NavLink></li>
                          <li class="current"><NavLink to="/dashboard-favorites">Favorites</NavLink></li>
                          <li><a href="#!">Playlists</a></li>
                          <li><a href="#!">Reviews</a></li>
                          <li><a href="#!">Friends</a></li>
                      </ul>
                      
                      
                      <div class="row">
      
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="item-listing-container-skrn">
                                  <a href="dashboard-movie-profile.html"><img src="https://via.placeholder.com/500x707" alt="Listing"/></a>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><a href="dashboard-movie-profile.html">Fantastic Beasts</a></h6>
                                        <div
                                          class="circle-rating-pro"
                                          data-value="0.86"
                                          data-animation-start-value="0.86"
                                          data-size="32"
                                          data-thickness="3"
                                          data-fill="{
                                            &quot;color&quot;: &quot;#42b740&quot;
                                          }"
                                          data-empty-fill="#def6de"
                                          data-reverse="true"
                                        ><span style={{color: '#ff4141' }}>8.6</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="item-listing-container-skrn">
                                  <a href="dashboard-movie-profile.html"><img src="https://via.placeholder.com/500x707" alt="Listing"/></a>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><a href="dashboard-movie-profile.html">Edge of Tomorrow</a></h6>
                                        <div
                                          class="circle-rating-pro"
                                          data-value="0.72"
                                          data-animation-start-value="0.72"
                                          data-size="32"
                                          data-thickness="3"
                                          data-fill="{
                                            &quot;color&quot;: &quot;#42b740&quot;
                                          }"
                                          data-empty-fill="#def6de"
                                          data-reverse="true"
                                        ><span style={{color: '#ff4141' }}>7.2</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="item-listing-container-skrn">
                                  <a href="dashboard-movie-profile.html"><img src="https://via.placeholder.com/500x707" alt="Listing"/></a>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><a href="dashboard-movie-profile.html">Allegiant</a></h6>
                                        <div
                                          class="circle-rating-pro"
                                          data-value="0.86"
                                          data-animation-start-value="0.86"
                                          data-size="32"
                                          data-thickness="3"
                                          data-fill="{
                                            &quot;color&quot;: &quot;#42b740&quot;
                                          }"
                                          data-empty-fill="#def6de"
                                          data-reverse="true"
                                        ><span style={{color: '#ff4141' }}>8.6</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                              
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="item-listing-container-skrn">
                                  <a href="dashboard-movie-profile.html"><img src="https://via.placeholder.com/500x707" alt="Listing"/></a>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><a href="dashboard-movie-profile.html">Bad Neighbors 2</a></h6>
                                        <div
                                          class="circle-rating-pro"
                                          data-value="0.72"
                                          data-animation-start-value="0.72"
                                          data-size="32"
                                          data-thickness="3"
                                          data-fill="{
                                            &quot;color&quot;: &quot;#42b740&quot;
                                          }"
                                          data-empty-fill="#def6de"
                                          data-reverse="true"
                                        ><span style={{color: '#ff4141' }}>7.2</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                          
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="item-listing-container-skrn">
                                  <a href="dashboard-movie-profile.html"><img src="https://via.placeholder.com/500x707" alt="Listing"/></a>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><a href="dashboard-movie-profile.html">Star Wars: Rogue One</a></h6>
                                        <div
                                          class="circle-rating-pro"
                                          data-value="0.86"
                                          data-animation-start-value="0.86"
                                          data-size="32"
                                          data-thickness="3"
                                          data-fill="{
                                            &quot;color&quot;: &quot;#42b740&quot;
                                          }"
                                          data-empty-fill="#def6de"
                                          data-reverse="true"
                                        ><span style={{color: '#ff4141' }}>8.6</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                          <div class="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="item-listing-container-skrn">
                                  <a href="dashboard-movie-profile.html"><img src="https://via.placeholder.com/500x707" alt="Listing"/></a>
                                  <div class="item-listing-text-skrn">
                                      <div class="item-listing-text-skrn-vertical-align"><h6><a href="dashboard-movie-profile.html">The Imitation Game</a></h6>
                                        <div
                                          class="circle-rating-pro"
                                          data-value="0.6"
                                          data-animation-start-value="0.6"
                                          data-size="32"
                                          data-thickness="3"
                                          data-fill="{
                                            &quot;color&quot;: &quot;#ff4141&quot;
                                          }"
                                          data-empty-fill="#ffe1e1"
                                          data-reverse="true"
                                        ><span style={{color: '#ff4141' }}>6.0</span></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                          
                      </div>
                      <br/>
                      
                      
                  </div>
              </main>
              
              
              </div>
            </div>
        )
    }
}
</Consumer>
)
}
} 

export default withRouter(DashboardProfile);